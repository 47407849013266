import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { Form, Row, Col, Button, Modal, notification } from "antd";
import renderInput from "../utilities/inputForms";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Actions
import {
  createChoice,
  updateAddOnsChoices,
  toggleModalChoices,
  setFormChoices,
} from "../../redux/actions/AddOns";

// Utilities

const AddOnsChoiceModal = ({
  setChangeAddOnsChoices,
  addOnsGroupId,
  isNational,
}) => {
  const dispatch = useDispatch();

  const { modalChoices, formLoadingChoices, errorChoices, formChoices } =
    useSelector(
      ({ addOnsReducers }) => ({
        modalChoices: addOnsReducers.modalChoices,
        formLoadingChoices: addOnsReducers.formLoadingChoices,
        errorChoices: addOnsReducers.errorChoices,
        formChoices: addOnsReducers.formChoices,
      }),
      isEqual
    );

  const hasForm = formChoices ? Object.keys(formChoices).length !== 0 : false;

  const schema = yup.object().shape({
    name: yup.string().required("Choice title is required field"),
    price: yup
      .number()
      .typeError("Price must be a number") // Ensures input is a number
      .required("Price is a required field")
      .min(0, "Price should be 0 or higher"),
  });

  let defaultValues = {
    name: "",
    price: "",
  };

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModalChoices(false));
    dispatch(setFormChoices({}));
    setChangeAddOnsChoices(false);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async (value) => {
    try {
      if (hasForm) {
        const updatedData = {
          add_ons_choices_id: formChoices.id,
          name: value.name,
          price: value.price,
          isNational,
        };

        await dispatch(updateAddOnsChoices(updatedData));

        close();
        return openNotification("SUCCESS", "Succesuffyl Updated Choice");
      } else {
        const newData = {
          add_ons_groups_id: addOnsGroupId,
          name: value.name,
          price: value.price,
          isNational,
        };

        const { data } = await dispatch(createChoice(newData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("updating/creating add ons choices error:>>", error);
    }
  };

  useEffect(() => {
    if (errorChoices) openNotification("ERROR", errorChoices.message);
  }, [errorChoices]);

  useEffect(() => {
    if (formChoices) reset(formChoices);
  }, [formChoices, reset]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modalChoices}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Choice title",
                  name: "name",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Price",
                  name: "price",
                  errors: errors,
                  required: "true",
                  type: "number",
                },
                control
              )}
            </Col>
          </Row>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoadingChoices}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddOnsChoiceModal;
