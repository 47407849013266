import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Popconfirm,
  DatePicker,
  Card,
  Modal,
  Skeleton,
} from "antd";
import { CheckSquareOutlined, ArrowDownOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// helpers
import formatCurrency from "../helpers/formatCurrency";

// Actions
import {
  fetchTotalBalance,
  fetchPartnerTransactions,
  fetchAllCommission,
} from "../redux/actions/partnerFinance";
import axiosInstance from "../helpers/axios";

const { Search } = Input;

const PartnerFinance = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { RangePicker } = DatePicker;

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);

  const [modal, setModal] = useState(false);
  const [partnerCommision, setPartnerCommission] = useState(0);
  const [operatorCommission, setOperatorCommission] = useState(0);
  const [subOperatorCommission, setSubOperatorCommission] = useState(0);
  const [customerRefferralsCom, setCustomerRefferalsCom] = useState(0);
  const [affliateCommission, setAffliateCommission] = useState(0);
  const [riderReferrals, setRiderReferrals] = useState(0);
  const [merchantCommission, setMerchantCommission] = useState(0);
  const [markUpCommission, setMarkUpCommission] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [filteredTotalBalance, setFilteredTotalBalance] = useState(0);
  const [
    filteredRiderReferralsCommission,
    setFilteredRiderReferralsCommission,
  ] = useState(0);
  const [filteredPartnerCommission, setFilteredPartner_commission] =
    useState(0);
  const [filteredMerchantCommissions, setFilteredMerchantCommissions] =
    useState(0);
  const [filteredMarkUpCommissions, setFilteredMarkUpCommissions] = useState(0);

  const [filteredOperatorCommissions, setFilteredOperatorCommissions] =
    useState(0);
  const [filtereSubOperatorCommissions, setFilteredSubOperatorCommissions] =
    useState(0);
  const [filtereCustomerRefCommissions, setFilteredCustomerRefCommissions] =
    useState(0);
  const [filteredAffiliateCommissions, setFilteredAffiliateCommissions] =
    useState(0);

  const {
    loading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    totalBalance,
    data,
    rider_referrals,
    merchant_commissions,
    parner_commission,
    totalBalanceLoading,
    loadingAllCommission,
    markup_commissions,
    dataFiltered,
    sub_operator_commissions,
    operator_commissions,
    customer_ref_commissions,
    affiliate_commissions,
    userInfo,
  } = useSelector(
    ({ partnerFinanceReducer, userLoginReducer }) => ({
      data: partnerFinanceReducer.data,
      totalRecord: partnerFinanceReducer.totalRecord,
      nextPage: partnerFinanceReducer.nextPage,
      previousPage: partnerFinanceReducer.previousPage,
      currentPage: partnerFinanceReducer.currentPage,
      loading: partnerFinanceReducer.loading,
      totalBalance: partnerFinanceReducer.totalBalance,
      parner_commission: partnerFinanceReducer.parner_commission,
      rider_referrals: partnerFinanceReducer.rider_referrals,
      merchant_commissions: partnerFinanceReducer.merchant_commissions,
      markup_commissions: partnerFinanceReducer.markup_commissions,
      sub_operator_commissions: partnerFinanceReducer.sub_operator_commissions,
      operator_commissions: partnerFinanceReducer.operator_commissions,
      customer_ref_commissions: partnerFinanceReducer.customer_ref_commissions,
      affiliate_commissions: partnerFinanceReducer.affiliate_commissions,
      totalBalanceLoading: partnerFinanceReducer.loadingTotalBalance,
      loadingAllCommission: partnerFinanceReducer.loadingAllCommission,
      dataFiltered: partnerFinanceReducer.dataFiltered,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    const i = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    const payload = {
      searchText: delaySearchText,
      page: 1,
      limit: 15,
      fromdateFilter:
        formatDate(selectedDate) === "Invalid Date"
          ? undefined
          : formatDate(selectedDate) + "T" + "00:00:00",
      todateFilter:
        formatDate(toSelectedDate) === "Invalid Date"
          ? undefined
          : formatDate(toSelectedDate) + "T" + "23:59:59",
    };

    if (formatDate(selectedDate) !== undefined) {
      if (payload.searchText !== "") {
        dispatch(fetchPartnerTransactions(i));
      } else {
        dispatch(fetchPartnerTransactions(payload));
      }
    } else {
      dispatch(fetchPartnerTransactions(i));
    }
  }, [delaySearchText, toSelectedDate, selectedDate, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };
    dispatch(fetchPartnerTransactions(payload));
    if (payload.searchText === "") {
      dispatch(fetchPartnerTransactions(payload));
    }
  };

  // useEffect(() => {
  //   const payload = {
  //     page: 1,
  //     limit: 15,
  //     searchText,
  //   };
  //   if (payload.searchText === "") {
  //     dispatch(fetchPartnerTransactions(payload));
  //   }
  // }, [dispatch]);

  useEffect(() => {
    // partner commissiom
    const i =
      (parseFloat(parner_commission) / parseFloat(totalBalance)) * 100 || 0;

    // rider referrals commissions
    const b =
      (parseFloat(rider_referrals) / parseFloat(totalBalance)) * 100 || 0;

    // merchant commissions
    const j =
      (parseFloat(merchant_commissions) / parseFloat(totalBalance)) * 100 || 0;

    // markup commissions
    const v =
      (parseFloat(markup_commissions) / parseFloat(totalBalance)) * 100 || 0;

    // operator commissions
    const l =
      (parseFloat(operator_commissions) / parseFloat(totalBalance)) * 100 || 0;

    // sub operator commissions
    const k =
      (parseFloat(sub_operator_commissions) / parseFloat(totalBalance)) * 100 ||
      0;

    // customer referrals commissions
    const c =
      (parseFloat(customer_ref_commissions) / parseFloat(totalBalance)) * 100 ||
      0;

    // affiliate commissions
    const r =
      (parseFloat(affiliate_commissions) / parseFloat(totalBalance)) * 100 || 0;

    // Total earnings
    const a =
      parseFloat(parner_commission) +
      parseFloat(rider_referrals) +
      parseFloat(merchant_commissions) +
      parseFloat(markup_commissions) +
      parseFloat(operator_commissions) +
      parseFloat(sub_operator_commissions) +
      parseFloat(customer_ref_commissions) +
      parseFloat(affiliate_commissions);

    // State commissions
    setTotalEarnings(a);
    setPartnerCommission(i.toFixed(2));
    setRiderReferrals(b.toFixed(2));
    setMerchantCommission(j.toFixed(2));
    setMarkUpCommission(v.toFixed(2));
    setOperatorCommission(l.toFixed(2));
    setSubOperatorCommission(k.toFixed(2));
    setCustomerRefferalsCom(c.toFixed(2));
    setAffliateCommission(r.toFixed(2));
  }, [
    totalBalance,
    merchant_commissions,
    rider_referrals,
    parner_commission,
    selectedDate,
  ]);

  useEffect(() => {
    let filterBalance = [];
    let filteredDfCommission = [];
    let filteredRrCommission = [];
    let filteredMCommission = [];
    let filteredMarpUpCommission = [];
    let operatorCommission = [];
    let subOperatorCommission = [];
    let customerRefCommission = [];
    let affiliateCommission = [];

    if (dataFiltered.length !== 0) {
      dataFiltered?.rows
        .filter((dd) => dd.entry_type === "debit")
        .map((d) => filterBalance.push(d.amount));

      const totalFilterBalance = filterBalance.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      setFilteredTotalBalance(totalFilterBalance.toFixed(2));

      // Df commission
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 7)
        .map((dd) => filteredDfCommission.push(dd.amount));
      const totalFilteredDfCommission = filteredDfCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const i =
        (parseFloat(totalFilteredDfCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setFilteredPartner_commission(totalFilteredDfCommission.toFixed(2));
      setPartnerCommission(i.toFixed(2));

      // Rrc commission
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 15)
        .map((dd) => filteredRrCommission.push(dd.amount));
      const totalFilteredRrCommission = filteredRrCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const b =
        (parseFloat(totalFilteredRrCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setFilteredRiderReferralsCommission(totalFilteredRrCommission.toFixed(2));
      setRiderReferrals(b.toFixed(2));

      // M commission
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 21)
        .map((dd) => filteredMCommission.push(dd.amount));
      const totalFilteredMCommission = filteredMCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const j =
        (parseFloat(totalFilteredMCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setMerchantCommission(j.toFixed(2));
      setFilteredMerchantCommissions(totalFilteredMCommission.toFixed(2));

      // Mark up Commissions
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 25)
        .map((dd) => filteredMarpUpCommission.push(dd.amount));
      const totalFilteredMarkupCommission = filteredMarpUpCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const markUp =
        (parseFloat(totalFilteredMarkupCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setMarkUpCommission(markUp.toFixed(2));
      setFilteredMarkUpCommissions(totalFilteredMarkupCommission.toFixed(2));

      // Operator commissions
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 33)
        .map((dd) => operatorCommission.push(dd.amount));
      const totalFilteredOperatorCommission = operatorCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const operatorCom =
        (parseFloat(totalFilteredOperatorCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setOperatorCommission(operatorCom.toFixed(2));
      setFilteredOperatorCommissions(
        totalFilteredOperatorCommission.toFixed(2)
      );

      // Sub operator commissions
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 32)
        .map((dd) => subOperatorCommission.push(dd.amount));
      const totalFilteredSubOperatorCommission = subOperatorCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const subOperatorCom =
        (parseFloat(totalFilteredSubOperatorCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setSubOperatorCommission(subOperatorCom.toFixed(2));
      setFilteredSubOperatorCommissions(
        totalFilteredSubOperatorCommission.toFixed(2)
      );

      // Customer ref commissions
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 34)
        .map((dd) => customerRefCommission.push(dd.amount));
      const totalFiltereCustomerRefCommission = customerRefCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const customerRefCom =
        (parseFloat(totalFiltereCustomerRefCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setCustomerRefferalsCom(customerRefCom.toFixed(2));
      setFilteredCustomerRefCommissions(
        totalFiltereCustomerRefCommission.toFixed(2)
      );

      // Affiliate commissions
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 28)
        .map((dd) => affiliateCommission.push(dd.amount));
      const totalFiltereAffiliateCommission = affiliateCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const affiliateCom =
        (parseFloat(totalFiltereAffiliateCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setAffliateCommission(affiliateCom.toFixed(2));
      setFilteredAffiliateCommissions(
        totalFiltereAffiliateCommission.toFixed(2)
      );
    } else {
      setFilteredTotalBalance(0);
      setFilteredPartner_commission(0);
      setFilteredRiderReferralsCommission(0);
      setFilteredMerchantCommissions(0);
      setFilteredAffiliateCommissions(0);
      setFilteredCustomerRefCommissions(0);
      setFilteredSubOperatorCommissions(0);
      setFilteredOperatorCommissions(0);
    }
  }, [dataFiltered]);

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ marginTop: -30, fontSize: 30, marginBottom: -5 }}>
          All transactions
        </h1>

        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(
        fetchPartnerTransactions({
          ...nextPage,
          fromdateFilter:
            formatDate(selectedDate) === "Invalid Date"
              ? undefined
              : formatDate(selectedDate) + "T" + "00:00:00",
          todateFilter:
            formatDate(toSelectedDate) === "Invalid Date"
              ? undefined
              : formatDate(toSelectedDate) + "T" + "23:59:59",
        })
      );
    } else {
      dispatch(
        fetchPartnerTransactions({
          ...nextPage,
        })
      );
    }
  };

  const onClickPreviousPage = () => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(
        fetchPartnerTransactions({
          ...previousPage,
          fromdateFilter:
            formatDate(selectedDate) === "Invalid Date"
              ? undefined
              : formatDate(selectedDate) + "T" + "00:00:00",
          todateFilter:
            formatDate(toSelectedDate) === "Invalid Date"
              ? undefined
              : formatDate(toSelectedDate) + "T" + "23:59:59",
        })
      );
    } else {
      dispatch(
        fetchPartnerTransactions({
          ...previousPage,
        })
      );
    }
  };

  const onClickFirstPage = () => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(
        fetchPartnerTransactions({
          ...defaultPage,
          fromdateFilter:
            formatDate(selectedDate) === "Invalid Date"
              ? undefined
              : formatDate(selectedDate) + "T" + "00:00:00",
          todateFilter:
            formatDate(toSelectedDate) === "Invalid Date"
              ? undefined
              : formatDate(toSelectedDate) + "T" + "23:59:59",
        })
      );
    } else {
      dispatch(
        fetchPartnerTransactions({
          ...defaultPage,
        })
      );
    }
  };

  const onClickLastPage = () => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(
        fetchPartnerTransactions({
          page: Math.ceil(totalRecord / 15),
          limit: 15,
          fromdateFilter:
            formatDate(selectedDate) === "Invalid Date"
              ? undefined
              : formatDate(selectedDate) + "T" + "00:00:00",
          todateFilter:
            formatDate(toSelectedDate) === "Invalid Date"
              ? undefined
              : formatDate(toSelectedDate) + "T" + "23:59:59",
        })
      );
    } else {
      dispatch(
        fetchPartnerTransactions({
          page: Math.ceil(totalRecord / 15),
          limit: 15,
        })
      );
    }
  };
  //   const onCheck = async (payload) => {
  //     dispatch(updatePayablesStatus(payload));
  //   };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate(dates[0]);

      setToSelectedDate(dates[1]);
    } else {
      setSelectedDate(null);
      setToSelectedDate(null);
    }
  };

  const [isBalanceAccurate, setIsBalanceAccurate] = useState(true);
  const checkBalanceAccuracy = async () => {
    try {
      const response = await axiosInstance(userInfo.token).get(
        `https://api.dorydelivery.com/api/utility/match-partner-wallet-balance?partnerId=${userInfo.id}`
      );
      if (response.data.isAccurate) {
        setIsBalanceAccurate(true);
      } else {
        setIsBalanceAccurate(false);
      }
    } catch (error) {
      console.log(">>> error checking balance accuracy:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Ammount",
        accessor: (row) => {
          if (row.entry_type === "debit") {
            return <div style={{ color: "green" }}> + {row.amount}</div>;
          }
          if (row.entry_type === "credit") {
            return <div style={{ color: "tomato" }}> - {row.amount}</div>;
          }
        },
      },

      {
        Header: "Reference Number",
        accessor: "reference_number",
      },
      {
        Header: "Date",
        accessor: (row) => {
          const utcTime = moment.utc(row.created_at);
          return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed
        },
      },
      {
        Header: "Transaction type",
        accessor: (row) => row["transaction_type.description"],
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchPartnerTransactions(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    dispatch(fetchTotalBalance());
    dispatch(fetchAllCommission());
  }, [dispatch]);

  useEffect(() => {
    checkBalanceAccuracy();
  }, []);

  const renderContent = () => {
    return (
      <div style={{ overflowX: "auto" }}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            gap: 20,
          }}
        >
          <h1 style={{ fontSize: 30, marginLeft: 45 }}>E-wallet</h1>
          <div style={{ marginBottom: 10 }}>
            <RangePicker
              style={{ width: "20vw" }}
              value={[selectedDate, toSelectedDate]}
              onChange={handleDateChange}
              placeholder={["Start Date", "End Date"]}
              format="YYYY-MM-DD"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "flex-start",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Card
            style={{
              boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
              overflowX: "auto",
              width: screenWidth < 1920 && "100%",
            }}
          >
            <div style={{ width: screenWidth < 1920 ? "100%" : "50%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 9,
                }}
              >
                {totalBalanceLoading ? (
                  <Skeleton.Button
                    active
                    style={{
                      width: 640,
                      marginLeft: 25,
                      marginBottom: 20,
                      height: 130,
                    }}
                    loading={totalBalanceLoading}
                  />
                ) : (
                  <Card
                    style={{
                      width: 640,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                      marginLeft: 25,
                      marginBottom: 20,
                      height: 130,
                    }}
                  >
                    <div
                      style={{
                        marginTop: -20,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {isBalanceAccurate ? (
                        <div>
                          <h1
                            style={{
                              fontSize: 20,
                              marginTop: 17,
                              color: "gray",
                            }}
                          >
                            Available Balance
                          </h1>
                          <h2 style={{ fontSize: 30, marginTop: -10 }}>
                            {formatCurrency(totalBalance || 0)}
                          </h2>
                        </div>
                      ) : (
                        <div>
                          <h1
                            style={{
                              fontSize: 22,
                              color: "gray",
                              marginTop: 8,
                            }}
                          >
                            Oops!
                          </h1>
                          <p
                            style={{
                              fontSize: 16,
                              color: "gray",
                            }}
                          >
                            Something went wrong. Please reach out to our
                            customer support on Discord for assistance.
                          </p>
                        </div>
                      )}

                      {/* <Button
                        // onClick={() => setModal(true)}
                        onClick={() =>
                          alert(
                            "This feature is currently under maintenance as we work on improving certain aspects of our e-wallet system. Thank you for your patience!"
                          )
                        }
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: 145,
                          height: 65,
                          fontSize: 20,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 5,
                        }}
                      >
                        <ArrowDownOutlined />
                        <div>Withdraw</div>
                      </Button> */}

                      <Button
                        style={{
                          width: 145,
                          height: 65,
                          fontSize: 20,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 5,
                          backgroundColor: isBalanceAccurate
                            ? "#1677ff"
                            : "gray",

                          color: "#fff",
                          borderRadius: 12,
                        }}
                        onClick={() => {
                          setModal(true);
                          // history.push({
                          //   pathname: "/fund-transfer",
                          //   state: { availableBalance: totalBalance },
                          // });
                        }}
                        disabled={totalBalance == 0 || !isBalanceAccurate}
                        to={"/fund-transfer"}
                        state={{ availableBalance: totalBalance }}
                      >
                        Withdraw
                      </Button>
                    </div>
                  </Card>
                )}

                {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 640,
                      height: 130,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 640,
                      height: 130,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div style={{ marginTop: -20 }}>
                      <h1
                        style={{ fontSize: 20, marginTop: 17, color: "gray" }}
                      >
                        Total Earnings
                      </h1>

                      <h2 style={{ fontSize: 30, marginTop: -10 }}>
                        {filteredTotalBalance !== 0
                          ? formatCurrency(filteredTotalBalance)
                          : formatCurrency(totalEarnings || 0)}
                      </h2>
                    </div>
                  </Card>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  width: 700,
                  flexWrap: "wrap",
                }}
              >
                {userInfo.role === "operator_admin" && (
                  <>
                    {loadingAllCommission ? (
                      <Skeleton.Button
                        active
                        style={{
                          marginLeft: 25,
                          marginBottom: 15,
                          width: 302,
                          height: 110,
                        }}
                        loading={loadingAllCommission}
                      />
                    ) : (
                      <Card
                        title={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>Area Commission {"(10%)"}</div>
                          </div>
                        }
                        style={{
                          marginLeft: 25,
                          marginBottom: 15,
                          width: 302,
                          height: 110,
                          boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <h2 style={{ fontSize: 20, marginTop: -10 }}>
                            {filteredPartnerCommission !== 0
                              ? formatCurrency(filteredPartnerCommission)
                              : formatCurrency(parner_commission)}
                          </h2>
                          <div style={{ color: "green", marginTop: -10 }}>
                            {partnerCommision || 0} %
                          </div>
                        </div>
                      </Card>
                    )}
                  </>
                )}
                {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Rider Referral Commission {"(5%)"}</div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filteredRiderReferralsCommission !== 0
                          ? formatCurrency(filteredRiderReferralsCommission)
                          : formatCurrency(rider_referrals)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {riderReferrals || 0}%
                      </div>
                    </div>
                  </Card>
                )}
                <>
                  {loadingAllCommission ? (
                    <Skeleton.Button
                      active
                      style={{
                        marginLeft: 25,
                        marginBottom: 15,
                        width: 302,
                        height: 110,
                      }}
                      loading={loadingAllCommission}
                    />
                  ) : (
                    <Card
                      title={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Markup Commission</div>
                        </div>
                      }
                      style={{
                        marginLeft: 25,
                        marginBottom: 15,
                        width: 302,
                        height: 110,
                        boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <h2 style={{ fontSize: 20, marginTop: -10 }}>
                          {filteredMarkUpCommissions !== 0
                            ? formatCurrency(filteredMarkUpCommissions)
                            : formatCurrency(markup_commissions)}
                        </h2>
                        <div style={{ color: "green", marginTop: -10 }}>
                          {markUpCommission || 0}%
                        </div>
                      </div>
                    </Card>
                  )}
                </>

                {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Subscription Commission {"(80%)"}</div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filteredMerchantCommissions !== 0
                          ? formatCurrency(filteredMerchantCommissions)
                          : formatCurrency(merchant_commissions)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {merchantCommission || 0}%
                      </div>
                    </div>
                  </Card>
                )}
                {userInfo.role === "sub_operator" && (
                  <>
                    {loadingAllCommission ? (
                      <Skeleton.Button
                        active
                        style={{
                          marginLeft: 25,
                          marginBottom: 15,
                          width: 302,
                          height: 110,
                        }}
                        loading={loadingAllCommission}
                      />
                    ) : (
                      <Card
                        title={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>Customer Referral Commission </span>
                              <span>{`(5%)`}</span>
                            </div>
                          </div>
                        }
                        style={{
                          marginLeft: 25,
                          marginBottom: 15,
                          width: 302,
                          height: 110,
                          boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <h2 style={{ fontSize: 20, marginTop: -10 }}>
                            {filtereCustomerRefCommissions !== 0
                              ? formatCurrency(filtereCustomerRefCommissions)
                              : formatCurrency(customer_ref_commissions)}
                          </h2>
                          <div style={{ color: "green", marginTop: -10 }}>
                            {customerRefferralsCom || 0}%
                          </div>
                        </div>
                      </Card>
                    )}
                  </>
                )}
                {/* {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>Affiliate Commission</span>
                          <div style={{ fontSize: 15 }}>500 pesos</div>
                        </div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filteredAffiliateCommissions !== 0
                          ? formatCurrency(filteredAffiliateCommissions)
                          : formatCurrency(affiliate_commissions)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {affliateCommission || 0}%
                      </div>
                    </div>
                  </Card>
                )} */}
                {/* {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>Operator Commission</span>
                          <div style={{ fontSize: 15 }}>5000 pesos</div>
                        </div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filteredOperatorCommissions !== 0
                          ? formatCurrency(filteredOperatorCommissions)
                          : formatCurrency(operator_commissions)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {operatorCommission || 0}%
                      </div>
                    </div>
                  </Card>
                )} */}
                {/* {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>Sub Operator Commission</span>
                          <div style={{ fontSize: 15 }}>1000 pesos</div>
                        </div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filtereSubOperatorCommissions !== 0
                          ? formatCurrency(filtereSubOperatorCommissions)
                          : formatCurrency(sub_operator_commissions)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {subOperatorCommission || 0}%
                      </div>
                    </div>
                  </Card>
                )} */}
              </div>
            </div>
          </Card>
          <div style={{ width: screenWidth < 1920 ? "100%" : "50%" }}>
            <Card style={{ boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)" }}>
              <ServerSideTable
                columns={columns}
                data={data}
                loading={loading}
                serverSidePagination={{
                  totalRecord,
                  onClickNextPage,
                  onClickPreviousPage,
                  currentPage,
                  nextPage,
                  previousPage,
                  onClickFirstPage,
                  onClickLastPage,
                }}
                customFilters={customFilters}
                dateFilters={{ selectedDate }}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              open={modal}
              onOk={() => {
                setModal(false);
              }}
              onCancel={() => {
                setModal(false);
              }}
              width={700}
            >
              <div style={{ padding: "20px 0" }}>
                <h2 style={{ fontSize: 24, marginBottom: 16 }}>
                  System Maintenance Notice
                </h2>
                <p
                  style={{
                    fontSize: 16,
                    color: "rgba(0, 0, 0, 0.65)",
                    lineHeight: 1.5,
                  }}
                >
                  The withdraw feature is currently under maintenance as we work
                  on improving our wallet security systems. We apologize for any
                  inconvenience this may cause. Please check back later.
                </p>
              </div>
            </Modal>
          </>
        }
      />
    </>
  );
};

export default PartnerFinance;
