import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";

import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Upload,
  Tag,
  Space,
  Select,
  Switch,
  Divider,
  Drawer,
  Typography,
  Card,
} from "antd";
import moment from "moment";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import {
  toggleModal,
  create,
  setForm,
  update,
  fetchAllMerchantTag,
  clearError,
} from "../../redux/actions/partnerMerchants";

// Utilities
import renderInput from "../utilities/inputForms";

//components
import TimeScheduleCard from "./timeScheduleCard";

const merchantTypeOption = [
  {
    value: "1",
    label: "Local",
  },
  {
    value: "2",
    label: "National",
  },
  {
    value: "3",
    label: "DTI",
  },
];

const UsersModal = ({ changePassword = false, setChangePassword }) => {
  const dispatch = useDispatch();

  const { modal, formLoading, error, form, tags, userInfo } = useSelector(
    ({ merchantsReducer, userLoginReducer }) => ({
      modal: merchantsReducer.modal,
      formLoading: merchantsReducer.formLoading,
      error: merchantsReducer.error,
      form: merchantsReducer.form,
      userInfo: userLoginReducer.userInfo,
      tags: merchantsReducer.tags,
    }),
    isEqual
  );

  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isBarangayEdited, setIsBarangayEdited] = useState(false);
  const [isNationalBrand, setIsNationalBrad] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedMerchantType, setSelectedMerchantType] = useState("");
  const [istwentyFourHrs, setIsTwentyFourHrs] = useState(false);

  // logo
  const [imageFileLogo, setImageFileLogo] = useState(null);
  const [imageUrlLogo, setImageUrlLogo] = useState("");
  const [loading, setLoading] = useState(false); // same with featured

  //featured Image
  const [imageFileFeatured, setImageFileFeatured] = useState(null);
  const [imageUrlFeatured, setImageUrlFeatured] = useState("");

  const handleChangeLogo = (info) => {
    setLoading(true);
    const file = info.file.originFileObj;

    setImageFileLogo(file);

    const localUrl = URL.createObjectURL(file);
    setImageUrlLogo(localUrl);
    setLoading(false);
  };

  useEffect(() => {
    if (form?.timeClose === "24:00:00" && form?.timeOpen === "00:00:00") {
      setIsTwentyFourHrs(true);
    }
  }, [form]);

  const handleChangeFeatured = (info) => {
    setLoading(true);
    const file = info.file.originFileObj;

    setImageFileFeatured(file);

    const localUrl = URL.createObjectURL(file);
    setImageUrlFeatured(localUrl);
    setLoading(false);
  };

  const uploadButtonLogo = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  // test

  const hasForm = form ? Object.keys(form).length !== 0 : false;
  const yourMaxSizeInBytes = 5 * 1024 * 1024;

  // useEffect(() => {
  //   if (hasForm) {
  //     return;
  //     const resultObjectProvince =
  //       phil.provinces.find((obj) => obj.name === form.province) || null;

  //     // obj.name --> json geer
  //     // form.province --> geocoded

  //     console.log(">>>> form", form);

  //     const cities = phil.getCityMunByProvince(resultObjectProvince.prov_code);

  //     setCities(cities);

  //     const resultObjectCity = cities.find((obj) => {
  //       let city = form.city;

  //       if (city === "baybay city") {
  //         city = "CITY OF BAYBAY";
  //       }

  //       return obj.name.toUpperCase() === city.toUpperCase();
  //     });

  //     const barangays = phil.getBarangayByMun(resultObjectCity.mun_code);

  //     setBarangays(barangays);
  //   }
  // }, [hasForm, form]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (hasForm) {
      if (form.merchant_type_id) {
        const option = merchantTypeOption.find(
          (option) => Number(option.value) === Number(form.merchant_type_id)
        );

        setSelectedMerchantType(option.value);
      }
    }
  }, [hasForm, form]);

  useEffect(() => {
    if (hasForm) {
      if (form.merchant_tags === null) {
        setSelectedTags([]);
      } else {
        const tagsArray = JSON.parse(form?.merchant_tags);

        setSelectedTags(tagsArray);
      }
    }
  }, [hasForm, form.merchant_tags, tags]);

  const schema = changePassword
    ? yup.object().shape({
        newPassword: yup.string().required("Password is required field"),
      })
    : yup.object().shape({
        // name: yup.string().required("Name is required field"),
        // contact_number: yup
        //   .string()
        //   .required("Contact Number is required field")
        //   .matches(
        //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        //     "Phone number is not valid"
        //   )
        //   .min(11, "too short")
        //   .max(11, "too long"),
        province: yup.string().required(),
        city: yup.string().required(),
        barangay: yup.string().required(),
        // username: yup.string().required("Username is required field"),
        longitude: yup
          .string()
          .matches(/^[\d.]+$/)
          .required("Longitude is a required field")
          .min(14, "Longitude must be at least 14 characters long"),
        latitude: yup
          .string()
          .matches(/^[\d.]+$/)
          .required("Latitude is a required field")
          .min(13, "Latitude must be at least 13 characters long"),
        landmark: yup.string().required("landmark is required field"),
        // timeOpen: istwentyFourHrs
        //   ? ""
        //   : yup.string().required("Time open is required field"),
        // timeClose: istwentyFourHrs
        //   ? ""
        //   : yup.string().required("Time close is required field"),
        // password: hasForm
        //   ? ""
        //   : yup.string().required("Password is required field"),
        // image: hasForm
        //   ? "" // For existing records, accept the image as a string (file name)
        //   : yup
        //       .mixed()
        //       .required("Image is required field")
        //       .test("fileSize", "File size is too large", (value) => {
        //         // Add a test to validate the file size if needed
        //         if (!value) return true; // Skip validation if no file is selected
        //         return value && value[0].size <= yourMaxSizeInBytes;
        //       }),
      });

  let defaultValues = changePassword
    ? { password: "" }
    : {
        name: "",
        province: "",
        city: "",
        barangay: "",
        username: "",
        contact_number: "",
        latitude: "",
        longitude: "",
        image: "",
        imageFile: null,
        landmark: "",
        imageFileLogo: "",
        featured_image_url: "",
      };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onCheck = (status) => {
    setIsNationalBrad(status);
  };

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setIsProvinceEdited(false);
    setChangePassword(false);
    setIsCityEdited(false);
    setIsBarangayEdited(false);
    setSelectedTags([]);
    setSelectedMerchantType("");
    setImageFileLogo(null);
    setImageFileFeatured(null);
    setImageUrlLogo("");
    setImageUrlFeatured("");
    setIsTwentyFourHrs(false);
  };

  const onChange = (checked) => {
    setIsTwentyFourHrs(!istwentyFourHrs);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
    setIsProvinceEdited(true);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    const barangaysJsonGeer = phil.getBarangayByMun(mun_code);
    setBarangays(barangaysJsonGeer);

    if (mun_code === "137404" || mun_code === 137404) {
      const additionalBrgy = [
        {
          id: 999999999,
          brgy_code: "Bago Bantay",
          name: "Bago Bantay",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
        {
          id: 111111111,
          brgy_code: "Kalayaan",
          name: "Kalayaan",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
        {
          id: 222222222,
          brgy_code: "West Avenue",
          name: "West Avenue",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
      ];
      setBarangays([...barangaysJsonGeer, ...additionalBrgy]);
    }
    setIsCityEdited(true);
  };

  const onSubmit = async (values) => {
    try {
      const parsedTimeOpen = moment(values.timeOpen, "HH:mm");
      let formattedTimeOpen = parsedTimeOpen.format("HH:mm:ss");

      const parsedTimeClose = moment(values.timeClose, "HH:mm");
      let formattedTimeClose = parsedTimeClose.format("HH:mm:ss");

      if (istwentyFourHrs) {
        formattedTimeOpen = "00:00:00";
        formattedTimeClose = "24:00:00";
      }

      if (hasForm) {
        if (changePassword) {
          const { data } = await dispatch(
            update({
              id: form.id,
              formData: { password: values.newPassword },
            })
          );
          close();
          return openNotification("SUCCESS", data.message);
        } else {
          if (selectedTags.length === 0) {
            openNotification("ERROR", "Select at least one tag");
            return;
          } else if (selectedTags.length > 8) {
            openNotification(
              "ERROR",
              "Tags should be limited to 8 selections, Please select the tags that best describe the merchant."
            );
            return;
          }

          const provinceCityBrgy = {
            province: isProvinceEdited
              ? phil.provinces.filter(
                  (province) => province.prov_code === values.province
                )[0].name
              : values.province,
            city: isCityEdited
              ? phil.city_mun.filter((city) => city.mun_code === values.city)[0]
                  .name
              : values.city,
            barangay: isBarangayEdited
              ? phil.barangays.filter(
                  (barangay) => barangay.brgy_code === values.barangay
                )[0]?.name
              : values.barangay,
          };

          // const povinceObj = phil.provinces.filter(
          //   (province) => province.name === provinceCityBrgy.province
          // )[0];

          if (
            provinceCityBrgy.barangay === "undefined" ||
            provinceCityBrgy.barangay === undefined
          ) {
            provinceCityBrgy.barangay = values.barangay;
          }

          // const regionObj = getRegionByProvince(povinceObj);

          const formData = new FormData();

          if (imageFileLogo) {
            formData.append("image", imageFileLogo);
          }

          if (imageFileFeatured) {
            formData.append("featuredImage", imageFileFeatured);
          }

          formData.append("name", form?.name);
          formData.append("contact_number", form?.contact_number);
          formData.append("province", provinceCityBrgy.province);
          formData.append("city", provinceCityBrgy.city);
          formData.append("barangay", provinceCityBrgy.barangay);
          formData.append("username", form?.username);
          formData.append("latitude", values.latitude);
          formData.append("longitude", values.longitude);
          formData.append("isNationalBrand", isNationalBrand);
          formData.append("timeOpen", formattedTimeOpen);
          formData.append("timeClose", formattedTimeClose);
          formData.append("tags", JSON.stringify(selectedTags));
          formData.append("merchantTypeId", selectedMerchantType);
          formData.append("landmark", values.landmark);

          // working update logo no featured image
          const { data } = await dispatch(update({ formData, id: form.id }));
          if (data) {
            openNotification("SUCCESS", data.message);
            close();
          }
        }
      } else {
        if (selectedTags.length === 0) {
          openNotification("ERROR", "Select at least one tag");
          return;
        } else if (selectedTags.length > 8) {
          openNotification(
            "ERROR",
            "Tags should be limited to 8 selections, Please select the tags that best describe the merchant."
          );
          return;
        }

        if (imageFileLogo === null) {
          openNotification("ERROR", "Logo is required");
          return;
        }

        if (imageFileFeatured === null) {
          openNotification("ERROR", "featured image is required");
          return;
        }

        if (selectedMerchantType === "") {
          openNotification("ERROR", "merchant type is required");
          return;
        }

        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === values.city
        )[0].name;
        let barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === values.barangay
        )[0]?.name;
        // const imageFile = values.image[0];
        // const fileName = imageFile.name;

        if (barangay === undefined || barangay === "undefined") {
          barangay = values.barangay;
        }

        const formData = new FormData();

        formData.append("featuredImage", imageFileFeatured);
        formData.append("name", values.name);
        formData.append("contact_number", values.contact_number);
        formData.append("province", province.name);
        formData.append("city", city);
        formData.append("barangay", barangay);
        formData.append("username", values.username);
        formData.append("password", values.password);
        formData.append("latitude", values.latitude);
        formData.append("longitude", values.longitude);
        formData.append("image", imageFileLogo);
        // formData.append("imageName", fileName);
        formData.append("isNationalBrand", isNationalBrand);
        formData.append("timeOpen", formattedTimeOpen);
        formData.append("timeClose", formattedTimeClose);
        formData.append("tags", JSON.stringify(selectedTags));
        formData.append("merchantTypeId", selectedMerchantType);
        // formData.append("merchantTypeId", selectedMerchantType);
        formData.append("landmark", values.landmark);
        formData.append("partner_id", userInfo.id);
        const { data } = await dispatch(create(formData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
          console.log(data.message);
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) {
      openNotification("ERROR", error);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (form) {
      reset(form);
    }
  }, [form, reset]);

  useEffect(() => {
    dispatch(fetchAllMerchantTag());
  }, [dispatch]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={700}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        {changePassword ? (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {renderInput(
                {
                  label: "Input New Password",
                  name: "newPassword",
                  errors: errors,
                  type: "password",
                },
                control
              )}
            </Col>
          </Row>
        ) : (
          <>
            <h1 style={{ fontSize: 20 }}>{form.name}</h1>
            {!hasForm && (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    {renderInput(
                      {
                        label: "Name",
                        name: "name",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                  <Col span={12}>
                    {renderInput(
                      {
                        label: "Contact Number (Optional)",
                        name: "contact_number",
                        errors: errors,
                      },
                      control
                    )}
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    {renderInput(
                      {
                        label: "User name",
                        name: "username",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                  {!hasForm && (
                    <Col span={12}>
                      {renderInput(
                        {
                          label: "Password",
                          name: "password",
                          errors: errors,
                          required: "true",
                          type: "password",
                        },
                        control
                      )}
                    </Col>
                  )}
                </Row>
              </>
            )}
            <Row gutter={[16, 16]}>
              <Col span={12}>
                {renderInput(
                  {
                    label: "Province",
                    name: "province",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: phil.provinces,
                    valueKey: "prov_code",
                    valueText: "name",
                    onChangeOutside: onChangeProvince,
                  },
                  control
                )}
              </Col>
              <Col span={12}>
                {renderInput(
                  {
                    label: "City",
                    name: "city",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: cities,
                    valueKey: "mun_code",
                    valueText: "name",
                    onChangeOutside: onChangeCity,
                  },
                  control
                )}
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                {renderInput(
                  {
                    label: "Barangay",
                    name: "barangay",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: barangays,
                    valueKey: "brgy_code",
                    valueText: "name",
                    onChangeOutside: () => setIsBarangayEdited(true),
                  },
                  control
                )}
              </Col>
              <Col span={12}>
                {renderInput(
                  {
                    label: "landmark",
                    name: "landmark",
                    errors: errors,
                    required: "true",
                    placeholder: "mall name/street/barangay",
                  },
                  control
                )}
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                {renderInput(
                  {
                    label: "Latitude",
                    name: "latitude",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>
              <Col span={12}>
                {renderInput(
                  {
                    label: "Longitude",
                    name: "longitude",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>
            </Row>

            {!hasForm && (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <p>
                      <span style={{ color: "red" }}> * </span>Merchant Type
                    </p>
                    <Select
                      defaultValue={selectedMerchantType}
                      value={selectedMerchantType}
                      showSearch
                      placeholder="Select merchant type"
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      name="merchantType"
                      onChange={(value) => {
                        setSelectedMerchantType(value);
                      }}
                      options={
                        userInfo.role === "admin"
                          ? merchantTypeOption
                          : [
                              {
                                value: "1",
                                label: "Local",
                              },
                              {
                                value: "2",
                                label: "National",
                              },
                            ]
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
            <br />

            <Row gutter={[16, 16]}>
              {/* include here comment */}
              {/* <Col span={10}>

                {renderInput(
                  {
                    type: "time",
                    label: "Time Open",
                    name: "timeOpen",
                    errors: errors,
                    required: "false",
                    disabled: istwentyFourHrs,
                  },
                  control
                )}
              </Col> */}
              {/* <Col span={10}>
                <Col className="gutter-row" span={24}>


                {renderInput(
                  {
                    type: "time",
                    label: "Time Close",
                    name: "timeClose",
                    errors: errors,
                    required: "false",
                    disabled: istwentyFourHrs,
                  },
                  control
                )}

                </Col>
              </Col> */}
              {/* <Col span={4}>

                <div style={{ paddingTop: 35 }}>
                  <Switch
                    checked={istwentyFourHrs}
                    onChange={onChange}
                    checkedChildren="24hrs"
                    unCheckedChildren="24hrs?"
                    defaultChecked={istwentyFourHrs}
                  />
                </div>
              </Col> */}
              <TimeScheduleCard />
            </Row>


            <>
              {selectedMerchantType === "1" && (
                <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                  <Col span={12}>
                    <>
                      <p>
                        <span style={{ color: "red" }}> * </span>Logo
                      </p>
                      <Upload
                        name="image"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={handleChangeLogo}
                      >
                        {hasForm ? (
                          imageUrlLogo ? (
                            <img
                              src={imageUrlLogo}
                              alt="avatar"
                              style={{
                                width: "100%",
                              }}
                            />
                          ) : (
                            <img
                              src={`https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/merchants/${form.image_url}`}
                              alt="avatar"
                              style={{
                                width: "100%",
                              }}
                            />
                          )
                        ) : imageUrlLogo ? (
                          <img
                            src={imageUrlLogo}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButtonLogo
                        )}
                      </Upload>
                    </>
                  </Col>
                  <Col span={12}>
                    {/* featured */}
                    <>
                      <p>
                        <span style={{ color: "red" }}> * </span>Featured image
                      </p>
                      <Upload
                        name="featuredImage"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={handleChangeFeatured}
                      >
                        {hasForm ? (
                          imageUrlFeatured ? (
                            <img
                              src={imageUrlFeatured}
                              alt="avatar"
                              style={{
                                width: "100%",
                              }}
                            />
                          ) : (
                            <img
                              src={`https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/partner-merchant-featured-image/${form.featured_image_url}`}
                              alt="avatar"
                              style={{
                                width: "100%",
                              }}
                            />
                          )
                        ) : imageUrlFeatured ? (
                          <img
                            src={imageUrlFeatured}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButtonLogo //same as featured
                        )}
                      </Upload>
                    </>
                  </Col>
                </Row>
              )}
              {!hasForm && (
                <Row gutter={[16]}>
                  <Col span={24}>
                    <p>
                      <span style={{ color: "red" }}> * </span>Tags
                    </p>
                    <Select
                      mode="multiple"
                      value={selectedTags}
                      filterOption={filterOption}
                      onChange={setSelectedTags}
                      defaultValue={[]}
                      showSearch
                      optionFilterProp="children"
                      style={{
                        width: "100%",
                      }}
                      options={tags}
                    />
                  </Col>
                </Row>
              )}
            </>
          </>
        )}

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UsersModal;
