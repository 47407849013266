import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
} from "../actionTypes.js";

import actions from "../actionTypes";
const { USER } = actions;

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
};

const userInitialState = {
  form: {},
  data: [],
  client: {},
  formLoading: false,
  loading: false,
  error: null,
  modal: false,
  modalView: false,
  referralLinks: {},
  isModalSubscriptionClosable: false,
  shouldShowSubscriptionModal: false,
};

export const userLoginReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case USER_LOGIN_SUCCESS:
      return { ...state, loading: false, userInfo: payload };
    case USER_LOGIN_FAIL:
      return { ...state, loading: false, error: payload };
    case USER_LOGOUT:
      return initialState;

    case USER.FETCHREFERRALLINK.REQUEST:
      return { ...state, loading: true, error: null };
    case USER.FETCHREFERRALLINK.SUCCESS:
      return { ...state, loading: false, referralLinks: payload.data };
    case USER.FETCHREFERRALLINK.FAILURE:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export const userRegisterReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_REGISTER_REQUEST:
      return { ...state, loading: true, error: null };
    case USER_REGISTER_SUCCESS:
      return { ...state, loading: false, userInfo: payload };
    case USER_REGISTER_FAIL:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};

export const userReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER.TOGGLE:
      return { ...state, modal: payload, error: null };
    case USER.TOGGLEVIEW:
      return { ...state, modalView: payload, error: null };
    case USER.SET:
      return { ...state, form: payload };
    case USER.SETCLIENT:
      return { ...state, client: payload };
    case USER.CUSTOMUPDATE:
      return {
        ...state,
        data: state.data.map((d) =>
          d.id === payload.id ? { ...d, [payload.key]: payload.value } : d
        ),
      };

    case USER.FETCH.REQUEST:
      return { ...state, loading: true };
    case USER.FETCH.SUCCESS:
      return { ...state, data: payload.data, loading: false };
    case USER.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case USER.FETCHSINGLE.REQUEST:
      return { ...state, loading: true };
    case USER.FETCHSINGLE.SUCCESS:
      return { ...state, client: payload.data, loading: false };
    case USER.FETCHSINGLE.FAILURE:
      return { ...state, error: payload, loading: false };

    case USER.CREATE.REQUEST:
      return { ...state, formLoading: true };
    case USER.CREATE.SUCCESS:
      const { data } = payload;
      return {
        ...state,
        formLoading: false,
        data: [
          {
            ...data.result,
            type: data.userTypeId === 1 ? "Main" : "Client",
          },
          ...state.data,
        ],
      };
    case USER.CREATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case USER.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case USER.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        form: payload.data.result,
        data: state.data.map((d) =>
          d.id === payload.data.result.id
            ? {
                ...payload.data.result,
                type:
                  payload.data.result.userTypeId === "1" ? "Main" : "Client",
              }
            : d
        ),
      };

    case USER.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case USER.TOGGLESUBSCRIPTIONMODAL:
      return {
        ...state,
        shouldShowSubscriptionModal: payload.shouldShowModal,
        isModalSubscriptionClosable: payload.closable,
      };

    default:
      return state;
  }
};
