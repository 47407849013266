import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import {
  Form,
  Upload,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Select,
  Input,
  Switch,
  Checkbox,
} from "antd";
import axiosInstance from "../../helpers/axios";
import {
  toggleModal,
  createNationalMerchantMenu,
  setForm,
  updateNationalMerchantMenu,
} from "../../redux/actions/nationalMerchantMenu";
import {
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
// Utilities
import renderInput from "../utilities/inputForms";

const NationalMerchantMenuModal = ({ changePassword = false }) => {
  const [formAntd] = Form.useForm();

  const { modal, formLoading, error, form, userInfo } = useSelector(
    ({ nationalMerchantMenuReducer, userLoginReducer, CategoryReducer }) => ({
      modal: nationalMerchantMenuReducer.modal,
      formLoading: nationalMerchantMenuReducer.formLoading,
      error: nationalMerchantMenuReducer.error,
      form: nationalMerchantMenuReducer.form,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const dispatch = useDispatch();
  const { id } = useParams();
  const [hasMenuData, setHasMenuData] = useState(false);
  const [setvariant, setVariantStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [variantStatus, setvVriantStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [menu, setMenu] = useState([]);
  const [variations, setVariations] = useState([]);
  // const [variantId, setVariantId] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  // const [newVariants, setNewVariants] = useState(form?.Size);
  const [imageFileLogo, setImageFileLogo] = useState(null);
  const [imageUrlLogo, setImageUrlLogo] = useState("");
  const [loading, setLoading] = useState(false);

  const [hasAddOns, setHasAddOns] = useState(false);
  const [addOnsGroup, setAddOnsGroup] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  // const yourMaxSizeInBytes = 5 * 1024 * 1024;
  const schema = yup.object().shape({
    title: yup.string().required(),
    // sub_title: yup.string().required(),
    description: yup.string().required(),
    price: yup
      .string()
      .matches(
        /^(\d+(\.\d{1,2})?)?$/,
        "Price is not valid. Only numbers and decimal points are allowed"
      ),
  });
  console.log("id,", id);
  let defaultValues = {
    title: "",
    sub_title: "",
    description: "",
    price: "",
    image: "",
    imageFile: null,
  };

  const parseSizeOrvariants = (data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return [];
    }
  };

  useEffect(() => {
    if (form) {
      try {
        const parsedLinkedAddOns = JSON.parse(form?.linked_add_ons);
        setSelectedGroupIds(parsedLinkedAddOns);
        if (parsedLinkedAddOns.length !== 0) {
          setHasAddOns(true);
        }
      } catch (error) {
        console.log(">>> error parsing linked add ons");
      }
    }
  }, [form]);

  useEffect(() => {
    if (form && form.Size) {
      try {
        const parsedVariations = JSON.parse(form.Size);
        if (Array.isArray(parsedVariations)) {
          setVariantStatus(true);
          setVariations(parsedVariations);

          formAntd.setFieldsValue({
            variations: parsedVariations.map((b, index) => ({
              variantID: b.variantID,
              variant: b.variant,
              price: b.price,
              key: index,
            })),
          });
        }
      } catch (error) {
        console.error("Error parsing variations:", error);
      }
    } else {
      formAntd.setFieldsValue({
        variations: [],
      });
    }
  }, [form]);

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setSelectedItem({});
    setVariantStatus(false);
    setVariations([]);
    setImageUrlLogo("");
    setImageFileLogo(null);
    setStatus(null);
    setHasAddOns(false);
    setSelectedGroupIds([]);
  };

  const handleChangeLogo = (info) => {
    setLoading(true);
    const file = info.file.originFileObj;

    setImageFileLogo(file);

    const localUrl = URL.createObjectURL(file);
    setImageUrlLogo(localUrl);
    setLoading(false);
  };

  const handleAdd = () => {
    const currentYear = new Date().getFullYear().toString().slice(-2);
    const currentMonth = (new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const currentDate = new Date().getDate().toString().padStart(2, "0");
    const lastDigitOfTime = new Date().getMinutes().toString().slice(-1);
    const uniqueNumber1 = Math.floor(Math.random() * 10);
    const uniqueNumber2 = Math.floor(Math.random() * 10);

    const newVariantId = parseInt(
      currentYear +
        currentMonth +
        currentDate +
        lastDigitOfTime +
        uniqueNumber1.toString() +
        uniqueNumber2.toString()
    );

    setVariations([
      ...variations,
      { variantID: newVariantId, variant: "", price: "", isActive: true },
    ]);
  };

  const handleRemove = (index) => {
    const newVariations = [...variations];
    newVariations.splice(index, 1);
    setVariations(newVariations);
  };

  const handleChange = (index, key, value) => {
    const newVariations = [...variations];
    newVariations[index][key] = value;
    setVariations(newVariations);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const handleCheckboxPress = (groupId) => {
    setSelectedGroupIds((prevSelectedGroupIds) => {
      if (prevSelectedGroupIds.includes(groupId)) {
        return prevSelectedGroupIds.filter((id) => id !== groupId);
      } else {
        return [...prevSelectedGroupIds, groupId];
      }
    });
  };
  const fetchCreatedAddOnsGroup = useCallback(async () => {
    try {
      const { data } = await axiosInstance(userInfo.token).get(
        `/api/merchants/menu/add-ons-group/${id}?isNational=true`
      );
      setAddOnsGroup(data.result);
    } catch (error) {
      console.log(">>> error getting fetch add ons group with choices:", error);
    }
  }, [id, userInfo.token]);
  useEffect(() => {
    fetchCreatedAddOnsGroup();
  }, [fetchCreatedAddOnsGroup]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const fetchCategories = useCallback(async () => {
    try {
      // setGroceriaProductsLoading(true);
      const response = await axiosInstance(userInfo.token).get(
        `/api/national-merchants/fetch-categories/${id}`
      );
      setMenu(response?.data.data.result);
      // setGroceriaProductsLoading(false);
    } catch (error) {
      // setGroceriaProductsLoading(false);
      console.log(">>> error getting groceria products:", error);
    }
  }, [userInfo]);
  const onSelectProduct = (value) => {
    const selected = menu.find((d) => d.title === value);
    setSelectedItem(selected);
  };

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onCheck = (status) => {
    setVariantStatus(status);
  };
  // const variantStauts = (status) => {
  //   setvVriantStatus(status);
  //   if (!status) {
  //     setVariantIsActive({ isActive: false });
  //   } else {
  //     setVariantIsActive([]);
  //   }
  // };
  const setMenuStatus = (status) => {
    setStatus(status);
  };

  const onSubmit = async (values) => {
    try {
      setSaveLoading(true);
      if (hasForm) {
        let formData = new FormData();

        let newValues;
        if (imageFileLogo === null) {
          const variationsData = JSON.stringify(variations, null, 2);

          formData.append("title", values.title);
          formData.append("sub_title", values.sub_title);
          formData.append("description", values.description);
          formData.append("price", setvariant ? 0 : values.price);
          formData.append("national_merchant_id", id);
          formData.append(
            "national_merchant_categories_id",
            form.national_merchant_categories_id
          );
          formData.append(
            "variants",
            setvariant === false ? "" : variationsData
          );
          formData.append("id", form.id);
          formData.append("caterogy_name", form.category_title);
          formData.append("status", status !== null ? status : form.isActive);
          formData.append(
            "menuAddOns",
            hasAddOns === false
              ? JSON.stringify([])
              : JSON.stringify(selectedGroupIds)
          );
        } else {
          const variationsData = JSON.stringify(variations, null, 2);

          formData.append("title", values.title);
          formData.append("caterogy_name", form.category_title);
          formData.append("sub_title", values.sub_title);
          formData.append("description", values.description);
          formData.append("price", setvariant ? 0 : values.price);
          formData.append("national_merchant_id", id);
          formData.append(
            "national_merchant_categories_id",
            form.national_merchant_categories_id
          );
          formData.append("image", imageFileLogo);
          formData.append(
            "variants",
            setvariant === false ? "" : variationsData
          );
          formData.append("id", form.id);
          formData.append("status", status !== null ? status : form.isActive);
          formData.append(
            "menuAddOns",
            hasAddOns === false
              ? JSON.stringify([])
              : JSON.stringify(selectedGroupIds)
          );
        }

        const { data } = await dispatch(updateNationalMerchantMenu(formData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
          setSaveLoading(false);
        }
      } else {
        if (imageFileLogo === null) {
          openNotification("ERROR", "Logo is required");
          setSaveLoading(false);
          return;
        }

        const formData = new FormData();
        const variationsData = JSON.stringify(variations, null, 2);

        formData.append("title", values.title);
        formData.append("sub_title", values.sub_title);
        formData.append("description", values.description);
        formData.append("price", setvariant ? 0 : values.price);
        formData.append("national_merchant_id", id);
        formData.append("national_merchant_categories_id", selectedItem.id);
        formData.append("image", imageFileLogo);
        formData.append("caterogy_name", selectedItem.title);
        formData.append("variants", setvariant === false ? "" : variationsData);
        formData.append(
          "menuAddOns",
          hasAddOns === false
            ? JSON.stringify([])
            : JSON.stringify(selectedGroupIds)
        );

        const { data } = await dispatch(createNationalMerchantMenu(formData));
        if (data) {
          openNotification("SUCCESS", data.message);
          setVariations([]);
          setSaveLoading(false);
          close();
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
      setSaveLoading(false);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (error) {
      openNotification("ERROR", error);
      setSaveLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (form) {
      reset(form);
    }
  }, [form, reset]);

  useEffect(() => {
    // Check if the menu array has data
    setHasMenuData(menu.length > 0);
  }, [menu]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  const uploadButtonLogo = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        form={formAntd}
      >
        <h1>Select Category</h1>

        <Select
          showSearch
          placeholder="Select a category for menu item"
          optionFilterProp="children"
          onChange={onSelectProduct}
          options={menu.map((d) => ({
            value: d.title,
          }))}
          style={{ width: "100%" }}
          value={selectedItem.title}
          disabled={hasForm}
          filterOption={(input, option) =>
            (option?.value ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.value ?? "")
              .toLowerCase()
              .localeCompare((optionB?.value ?? "").toLowerCase())
          }
        />

        <div style={{ marginTop: 15 }}></div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Title",
                name: "title",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
          {/* <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Sub title",
                  name: "sub_title",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col> */}
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Description",
                name: "description",
                errors: errors,
                required: "true",
                type: "textArea",
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Price",
                name: "price",
                errors: errors,
                required: "true",
                disabled: setvariant,
              },
              control
            )}
          </Col>

          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 19,
                marginTop: -25,
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <div style={{ fontSize: 15 }}>Add Variants</div>
              <Switch
                size="medium"
                checked={setvariant}
                onChange={(checked) => {
                  onCheck(checked);
                }}
                defaultValue={setvariant}
              />
            </div>
          </Col>
        </Row>

        <></>

        {form?.Size === undefined ||
        form?.Size === null ||
        form?.Size === "" ? (
          <>
            <Form.List name="variations" initialValue={[]}>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayout)}
                      label={index === 0 ? "Variations" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        noStyle
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 5,
                          }}
                        >
                          <Input
                            disabled={!setvariant}
                            placeholder="Variant name"
                            style={{ width: "60%" }}
                            value={variations[index]?.variant || ""}
                            onChange={(e) =>
                              handleChange(index, "variant", e.target.value)
                            }
                          />
                          <Input
                            disabled={!setvariant}
                            placeholder="Variant price"
                            style={{ width: "60%" }}
                            value={variations[index]?.price || ""}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              // Check if the input value contains only numbers and dots
                              if (
                                /^\d*\.?\d*$/.test(inputValue) ||
                                inputValue === ""
                              ) {
                                handleChange(index, "price", inputValue);
                              }
                            }}
                          />
                        </div>
                      </Form.Item>

                      {fields.length > 0 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            handleRemove(index);
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                        handleAdd();
                      }}
                      style={{ width: "60%" }}
                      icon={<PlusOutlined />}
                      disabled={!setvariant}
                    >
                      Add Variants
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        ) : (
          <>
            {variations.length !== 0 && variations !== "" && (
              <Form.List name="variations" initialValue={variations}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0 ? formItemLayout : formItemLayout)}
                        label={index === 0 ? "Variations" : ""}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          noStyle
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 5,
                              alignItems: "center",
                            }}
                          >
                            <Input
                              disabled={!setvariant}
                              placeholder="Variant name"
                              style={{ width: "60%" }}
                              value={variations[index]?.variant || ""}
                              onChange={(e) =>
                                handleChange(index, "variant", e.target.value)
                              }
                            />
                            <Input
                              disabled={!setvariant}
                              placeholder="Variant price"
                              style={{ width: "60%" }}
                              value={variations[index]?.price || ""}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input value contains only numbers and dots
                                if (
                                  /^\d*\.?\d*$/.test(inputValue) ||
                                  inputValue === ""
                                ) {
                                  handleChange(index, "price", inputValue);
                                }
                              }}
                            />
                            <Switch
                              size="small"
                              checked={variations[index]?.isActive}
                              onChange={(checked) => {
                                handleChange(index, "isActive", checked);
                              }}
                              defaultValue={variations[index]?.isActive}
                            />
                          </div>
                        </Form.Item>

                        {fields.length > 0 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              handleRemove(index);
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                          handleAdd();
                        }}
                        style={{ width: "60%" }}
                        icon={<PlusOutlined />}
                        disabled={!setvariant}
                      >
                        Add Variants
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
          </>
        )}

        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Image",
                name: "image",
                errors: errors,
                required: "false",
                type: "file",
              },
              control
            )}
          </Col>
        </Row> */}

        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 19,
              marginTop: -25,
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            <div style={{ fontSize: 15 }}>Add-Ons</div>
            <Switch
              size="medium"
              checked={hasAddOns}
              onChange={(checked) => {
                setHasAddOns(checked);
                setSelectedGroupIds([]);
              }}
              defaultValue={hasAddOns}
            />
          </div>
        </Col>
        {hasAddOns && addOnsGroup.length !== 0 ? (
          <>
            {addOnsGroup.map((a, index) => {
              const isChecked = selectedGroupIds.includes(a.id);
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: 10,
                    marginBottom: 5,
                  }}
                  key={index}
                >
                  <div>
                    <Checkbox
                      onChange={() => {
                        handleCheckboxPress(a.id);
                      }}
                      checked={isChecked}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                        {a?.name?.length > 24
                          ? a?.name.substring(0, 24 - 3) + "..."
                          : a?.name}
                      </p>
                      <p
                        style={{
                          color: a.isActive === 1 ? "#00BF63" : "red",
                          marginBottom: 0,
                        }}
                      >
                        {a.isActive === 1 ? "Active" : "Inactive"}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 3,
                        maxWidth: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {a.choices.length !== 0 &&
                        a.choices.map((b, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                backgroundColor: "#e0e0e0",
                                borderRadius: 20,
                                padding: "0px 4px",
                                alignSelf: "flex-start",
                                maxWidth: 150,
                                marginBottom: 5,
                              }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  marginBottom: 0,
                                  fontSize: 12,
                                  textAlign: "center",
                                }}
                              >
                                {b.name}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          hasAddOns && (
            <>
              <p>empty</p>
            </>
          )
        )}

        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <>
              <p>
                <span style={{ color: "red" }}> * </span>Logo
              </p>
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                onChange={handleChangeLogo}
              >
                {hasForm ? (
                  imageUrlLogo ? (
                    <img
                      src={imageUrlLogo}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    <img
                      src={`https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/partner-merchant-menu/${form.image_url}`}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  )
                ) : imageUrlLogo ? (
                  <img
                    src={imageUrlLogo}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButtonLogo
                )}
              </Upload>
            </>
          </Col>

          {/* <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 19,
                marginTop: -25,
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <div style={{ fontSize: 15 }}>Deactivate this menu</div>
              <Switch
                size="medium"
                checked={status !== null ? status : form.isActive === 1}
                onChange={(checked) => {
                  setMenuStatus(checked);
                }}
                defaultValue={form.isActive}
              />
            </div>
          </Col> */}
        </Row>
        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={saveLoading}
            disabled={saveLoading}
          >
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NationalMerchantMenuModal;
