import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { isEqual } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";
import {
  Form,
  Col,
  Button,
  Modal,
  notification,
  Divider,
  Row,
  Radio,
} from "antd";
import {
  toggleModalCreateRider as toggleModal,
  setFormCreateRider as setForm,
  createRider,
  updateRiderCommission,
} from "../../redux/actions/riderInMyArea";
import renderInput from "../utilities/inputForms";
import axiosInstance from "../../helpers/axios";

const schema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  username: yup.string().required("Username is required"),
  contact_number: yup
    .string()
    .required("Contact number is required")
    .matches(
      /^((\+63)?[0-9]{11})$/,
      "Contact number must be valid and exactly 11 digits"
    ),
  province: yup.string().required("Province is required"),
  city: yup.string().required("City is required"),
  barangay: yup.string().required("Barangay is required"),
  // service_province: yup.string().required("Service province is required"),
  service_city: yup.string().required("Service city is required"),
  email_address: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
  dob: yup
    .date()
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future"),
});

const defaultValues = {
  first_name: "",
  last_name: "",
  province: "",
  city: "",
  barangay: "",
  contact_number: "",
  password: "",
  dob: "",
  username: "",
  // service_province: "",
  service_city: "",
  email_address: "",
};

const CreateRiderModal = ({
  changeCommission = false,
  setChangeCommission,
  riderDetails = null,
  type = "operator_admin",
}) => {
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [serviceCities, setServiceCities] = useState([]);
  const [commission, setCommission] = useState(
    type === "operator_admin" ? 85 : 100
  );
  const [riderFormLoading, setRiderFormLoading] = useState(false);

  const { modalCreateRider, errorCreateModal, riderForm, userInfo } =
    useSelector(
      ({ riderReducer, userLoginReducer }) => ({
        modalCreateRider: riderReducer.modalCreateRider,
        errorCreateModal: riderReducer.errorCreateModal,
        riderForm: riderReducer.riderForm,
        userInfo: userLoginReducer.userInfo,
      }),
      isEqual
    );

  const resolver = changeCommission ? undefined : yupResolver(schema);

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver,
    defaultValues,
  });

  // const hasForm = riderForm && Object.keys(riderForm).length > 0;

  useEffect(() => {
    if (riderDetails?.rider_commission_percentage) {
      setCommission(Number(riderDetails.rider_commission_percentage));
    }
  }, [riderDetails]);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        if (userInfo.role === "operator_admin") {
          const area = JSON.parse(userInfo.grouped_areas);

          const restructured = area.map((a) => ({
            value: a,
            label: a,
          }));

          setServiceCities(restructured);
        } else {
          const { data } = await axiosInstance(userInfo.token).get(
            `/api/sub-operator/sub-operator-parent-area?subOperatorId=${userInfo.id}`
          );

          setServiceCities(data.result);
        }
      } catch (error) {
        console.error(">>>> error parsing or fetching data", error);
      }
    };

    fetchAreas();
  }, [userInfo.role, userInfo.grouped_areas, userInfo.id]);

  const handleRadioChange = (e) => {
    setCommission(e.target.value); // Update state when radio value changes
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
  };

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setServiceCities([]);
    setChangeCommission(false);
    setCommission(type === "operator_admin" ? 85 : 100);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async (value) => {
    try {
      setRiderFormLoading(true);

      if (changeCommission) {
        //  update commission

        const { data } = await dispatch(
          updateRiderCommission({
            rider_user_id: riderDetails.user_id,
            commission,
          })
        );

        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      } else {
        const province = phil.provinces.filter(
          (province) => province.prov_code === value.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === value.city
        )[0].name;
        const barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === value.barangay
        )[0].name;

        const newData = {
          ...value,
          city,
          barangay,
          province: province.name,
          service_province: userInfo.province,
          partner_id: userInfo.id,
          commission,
        };

        const { data } = await dispatch(createRider(newData));

        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
      setRiderFormLoading(false);
    } catch (error) {
      console.log(">>> Something Went Wrong With Creating Rider", error);
      setRiderFormLoading(false);
    }
  };

  useEffect(() => {
    if (errorCreateModal) openNotification("Error", errorCreateModal.message);
  }, [errorCreateModal]);

  useEffect(() => {
    if (riderForm) reset(riderForm);
  }, [riderForm, reset]);

  return (
    <Modal
      title={changeCommission ? "Update Rider" : "Add Rider"}
      open={modalCreateRider}
      width={550}
      footer={null}
      onCancel={close}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        {!changeCommission && (
          <>
            <Divider orientation="left">Personal Information</Divider>
            <Row gutter={16}>
              <Col span={12}>
                {renderInput(
                  { label: "First Name", name: "first_name", errors },
                  control
                )}
              </Col>
              <Col span={12}>
                {renderInput(
                  { label: "Last Name", name: "last_name", errors },
                  control
                )}
              </Col>
              <Col span={12}>
                {renderInput(
                  { type: "date", label: "Date of Birth", name: "dob", errors },
                  control
                )}
              </Col>
            </Row>

            <Divider orientation="left">Contact Information</Divider>
            <Row gutter={16}>
              <Col span={12}>
                {renderInput(
                  { label: "Contact Number", name: "contact_number", errors },
                  control
                )}
              </Col>
              <Col span={12}>
                {renderInput(
                  { label: "Email", name: "email_address", errors },
                  control
                )}
              </Col>
            </Row>

            <Divider orientation="left">Address Details</Divider>
            <Row gutter={16}>
              <Col span={12}>
                {renderInput(
                  {
                    label: "Province",
                    name: "province",
                    type: "select",
                    options: phil.provinces,
                    valueKey: "prov_code",
                    valueText: "name",
                    onChangeOutside: onChangeProvince,
                  },
                  control
                )}
              </Col>
              <Col span={12}>
                {renderInput(
                  {
                    label: "City",
                    name: "city",
                    type: "select",
                    options: cities,
                    valueKey: "mun_code",
                    valueText: "name",
                    onChangeOutside: onChangeCity,
                  },
                  control
                )}
              </Col>
              <Col span={24}>
                {renderInput(
                  {
                    label: "Barangay",
                    name: "barangay",
                    type: "select",
                    options: barangays,
                    valueKey: "brgy_code",
                    valueText: "name",
                  },
                  control
                )}
              </Col>
            </Row>

            <Divider orientation="left">Login Credentials</Divider>
            <Row gutter={16}>
              <Col span={12}>
                {renderInput(
                  { label: "Username", name: "username", errors },
                  control
                )}
              </Col>
              <Col span={12}>
                {renderInput(
                  {
                    label: "Password",
                    name: "password",
                    type: "password",
                    errors,
                  },
                  control
                )}
              </Col>
            </Row>

            <Divider orientation="left">Service Area</Divider>
            <Row gutter={16}>
              <Col span={24}>
                {renderInput(
                  {
                    label: "Service City",
                    name: "service_city",
                    type: "select",
                    options: serviceCities,
                    valueKey: "label",
                    valueText: "label",
                  },
                  control
                )}
              </Col>
            </Row>
          </>
        )}

        <Divider orientation="left">Rider Commission</Divider>
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={24}>
            <div>
              <Radio.Group onChange={handleRadioChange} value={commission}>
                {type === "sub_operator" && (
                  <>
                    <Radio value={100}>100%</Radio>
                    <Radio value={95}>95%</Radio>
                    <Radio value={90}>90%</Radio>
                  </>
                )}
                <Radio value={85}>85%</Radio>
                <Radio value={80}>80%</Radio>
                <Radio value={75}>75%</Radio>
              </Radio.Group>
            </div>
          </Col>
        </Row>

        <div className="text-right mt-5">
          <Button onClick={close} style={{ marginRight: "12px" }}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={riderFormLoading}
            disabled={riderFormLoading}
          >
            {changeCommission ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateRiderModal;
