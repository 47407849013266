import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";

const { CREATE_EMAIL } = actions;

export const createEmailAddress = (payload, otpCode, userId) => async (dispatch, getState) => {
  dispatch({ type: CREATE_EMAIL.REQUEST });

  const {
    userLoginReducer: { userInfo } = {} 
  } = getState();

  try {
    let token = "";
    let id = userId || ""; 

    if (userInfo) {
      token = userInfo.token || "";
      id = userInfo.id || id; 
    }

    if (!id) {
      throw new Error('ID is missing and no userId was provided');
    }

    console.log('Payload:', payload);

    const response = await axiosInstance(token).put(
      `/api/admin-account/create-email/${id}?otpCode=${otpCode}`,
      payload
    );

    if (userInfo) {
      const updatedUserInfo = { ...userInfo, email_address: payload.email_address };
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));

      console.log('Updated userInfo:', updatedUserInfo);
    }

    dispatch({
      type: CREATE_EMAIL.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating admin address:', error.message);

    dispatch({
      type: CREATE_EMAIL.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
