import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input, Badge, Switch } from "antd";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import Icon from "@mdi/react";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import AddOnsGroupModal from "../components/add-ons/modal";

// Actions
import {
  fetchGroups,
  toggleModalGroup,
  setFormGroup,
  updateAddOnsGroup,
} from "../redux/actions/AddOns";
import {
  Link,
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { mdiFoodOutline } from "@mdi/js";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const AddOns = () => {
  const { merchant_id, merchant_name } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isNational = false } = location.state || {};

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [changeAddOnsGroup, setChangeAddOnsGroup] = useState(false);

  const {
    loadingGroup,
    formLoadingGroup,
    dataGroup,
    totalRecordGroup,
    nextPageGroup,
    previousPageGroup,
    currentPageGroup,
  } = useSelector(
    ({ addOnsReducers }) => ({
      dataGroup: addOnsReducers.dataGroup,
      totalRecordGroup: addOnsReducers.totalRecordGroup,
      nextPageGroup: addOnsReducers.nextPageGroup,
      previousPageGroup: addOnsReducers.previousPageGroup,
      currentPageGroup: addOnsReducers.currentPageGroup,
      loadingGroup: addOnsReducers.loadingGroup,
      formLoadingGroup: addOnsReducers.formLoadingGroup,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      merchant_id,
      isNational,
    };
    dispatch(fetchGroups(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => {
    dispatch(toggleModalGroup());
  };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      merchant_id,
      isNational,
    };

    dispatch(fetchGroups(payload));
  };

  const onEdit = ({ data }) => {
    setChangeAddOnsGroup(true);
    dispatch(setFormGroup(data));
    dispatch(toggleModalGroup());
  };

  const onChange = (status, id) => {
    dispatch(
      updateAddOnsGroup({
        toggle: true,
        isActive: status,
        add_on_group_id: id,
        isNational,
      })
    );
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>{merchant_name} - Add Ons Group</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      merchant_id,
      isNational,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchGroups({
        ...nextPageGroup,
        searchText,
        merchant_id,
        isNational,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchGroups({
        ...previousPageGroup,
        searchText,
        merchant_id,
        isNational,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchGroups({
        ...defaultPage,
        searchText,
        merchant_id,
        isNational,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchGroups({
        page: Math.ceil(totalRecordGroup / 15),
        limit: 15,
        searchText,
        merchant_id,
        isNational,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Link
              to={{
                pathname: `/add-ons/choices`,
                state: { ...original, merchant_name, isNational },
              }}
            >
              <Button disabled={formLoadingGroup} size="small" type="text">
                <Tooltip title="View Choices">
                  <span className="anticon anticon-check-square">
                    <Icon path={mdiFoodOutline} size={0.7} />
                  </span>
                </Tooltip>
              </Button>
            </Link>

            {/* edit */}
            <Button
              disabled={formLoadingGroup}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>

            {/* swtich */}

            <Tooltip title="Update status">
              <Switch
                size="small"
                defaultChecked={original.isActive}
                onChange={(checked) => {
                  onChange(checked, original.id);
                }}
              />
            </Tooltip>
          </>
        ),
        width: 100,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: (row) => {
          if (row.type === "add_ons") {
            return "Add-ons";
          } else {
            return "Preference";
          }
        },
      },
      {
        Header: "Status",
        accessor: (row) =>
          row.isActive ? (
            <Badge status="success" text="Active" />
          ) : (
            <Badge status="error" text="Inactive" />
          ),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchGroups(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={dataGroup}
          loading={loadingGroup}
          serverSidePagination={{
            totalRecord: totalRecordGroup,
            onClickNextPage,
            onClickPreviousPage,
            currentPage: currentPageGroup,
            nextPage: nextPageGroup,
            previousPage: previousPageGroup,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <AddOnsGroupModal
              changeAddOnsGroup={changeAddOnsGroup}
              setChangeAddOnsGroup={setChangeAddOnsGroup}
              merchant_id={merchant_id}
              isNational={isNational}
            />
          </>
        }
      />
    </>
  );
};

export default AddOns;
