import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiHandCoinOutline } from "@mdi/js";

import {
  Layout,
  Form,
  Input,
  Badge,
  Button,
  Tag,
  Tooltip,
  notification,
  Popconfirm,
} from "antd";
import {
  CheckSquareOutlined,
  PlusOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
// Components
import axiosInstance from "../helpers/axios";
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import ReportAccountModal from "../components/reportAccountRider/reportAccountModal";
import CreateRiderModal from "../components/riderInMyArea/modal";
import moment from "moment/moment";
// Actions
import {
  toggleModalCreateRider,
  toggleModal,
  fetchRiderInMyArea,
} from "../redux/actions/riderInMyArea";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const RiderInMyAreaPage = () => {
  const dispatch = useDispatch();
  const [removeDeviceLoading, setRemoveDeviceLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [ids, setIds] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [changeCommission, setChangeCommission] = useState(false);
  const [riderDetails, setRiderDetails] = useState({});
  const {
    loading,
    riderInMyAreaData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    userInfo,
    reportLoading,
  } = useSelector(({ riderReducer, userLoginReducer }) => ({
    riderInMyAreaData: riderReducer.riderInMyAreaData,
    totalRecord: riderReducer.riderInMyAreaTotalRecord,
    nextPage: riderReducer.riderInMyAreaNextPage,
    previousPage: riderReducer.riderInMyAreaPreviousPage,
    currentPage: riderReducer.riderInMyAreaCurrentPage,
    loading: riderReducer.loading,
    userInfo: userLoginReducer.userInfo,
    reportLoading: riderReducer.reportLoading,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchRiderInMyArea(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchRiderInMyArea(payload));
  };

  const openModal = (data) => {
    dispatch(toggleModal());
    setIds(data.data);
  };

  const removeDeviceId = async (riderUserId) => {
    try {
      setRemoveDeviceLoading(true);
      const response = await axiosInstance(userInfo.token).put(
        `/api/rider-logout/${riderUserId}`
      );

      if (response.data.success === true) {
        notification.success({
          message: response.data.message,
        });
      }
      setRemoveDeviceLoading(false);
    } catch (error) {
      setRemoveDeviceLoading(false);
      console.log("removeDeviceId ----->", error);
    }
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 className="text-2xl md:text-3xl">Riders In My Area</h1>
        <div className="p-2 flex flex-col md:flex-row md:items-center justify-between bg-gray-100 gap-2">
          <Form
            layout="inline"
            className="w-full md:w-auto flex flex-col md:flex-row md:gap-4"
          >
            <Form.Item className="w-full md:w-auto">
              <Search
                placeholder="input search text"
                allowClear
                className="w-full md:w-30"
                value={searchText}
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchRiderInMyArea({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchRiderInMyArea({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchRiderInMyArea({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchRiderInMyArea({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };

  const onAdd = () => {
    dispatch(toggleModalCreateRider(true));
  };

  const onEdit = ({ data }) => {
    setChangeCommission(true);
    setRiderDetails(data);
    dispatch(toggleModalCreateRider(true));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              gap: 5,
            }}
          >
            {original.isCreatedByAdmin === 1 && (
              <Button
                size="small"
                type="text"
                onClick={() => onEdit({ data: original })}
              >
                <Tooltip title="Rider Commission">
                  <span className="anticon anticon-check-square">
                    <Icon path={mdiHandCoinOutline} size={0.7} />
                  </span>
                </Tooltip>
              </Button>
            )}

            <Button
              onClick={() => {
                console.log(original);
                openModal({
                  data: { partner_id: userInfo.id, rider_id: original.user_id },
                });
              }}
              disabled={
                original.reportDateSubmitted === "N/A"
                  ? false
                  : moment(original.reportDateSubmitted).format(
                      "YYYY-MM-DD"
                    ) === moment().format("YYYY-MM-DD") ||
                    moment(original.reportDateValid).format("YYYY-MM-DD") ===
                      moment().format("YYYY-MM-DD") ||
                    (moment(original.reportDateInvalid).format("YYYY-MM-DD") ===
                      moment().format("YYYY-MM-DD") &&
                      true)
              }
              size="small"
            >
              Suspend Rider
            </Button>
            <Popconfirm
              title="Are you sure you want to log out this rider from the Rider App?"
              onConfirm={() => removeDeviceId(original.user_id)}
              okText="Yes"
              cancelText="No"
              placement="right"
            >
              <Button
                loading={removeDeviceLoading}
                disabled={removeDeviceLoading}
                size="small"
              >
                Unlink Device
              </Button>
            </Popconfirm>
            {original.report_counts !== 3 && (
              <>
                {moment(original.reportDateSubmitted).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD") ||
                moment(original.reportDateValid).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD") ||
                moment(original.reportDateInvalid).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD") ? (
                  <div>
                    <Tooltip title={"You can report again after 24 hours"}>
                      <ExclamationCircleOutlined
                        style={{ color: "#4169E1", fontSize: 16 }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        ),
      },
      {
        Header: "Duty Status",
        accessor: (row) =>
          row.active_status === "offline" || row.active_status === null ? (
            <Badge status="error" text="Off Duty" />
          ) : (
            <Badge status="success" text="On Duty" />
          ),
      },
      {
        Header: "Operational Status",
        accessor: (row) =>
          !row.toppedUp ? (
            <Badge status="error" text="No Top-Up" />
          ) : (
            <Badge status="success" text="Ready for Duty" />
          ),
      },
      {
        Header: "Account status",
        accessor: (row) =>
          row.access_status !== "active" ? (
            <Badge status="error" text="Deactivated" />
          ) : (
            <Badge status="success" text="Active" />
          ),
      },
      {
        Header: "Name",
        accessor: "fullName",
      },
      {
        Header: "Contact number",
        accessor: "contact_number",
      },
      {
        Header: "preferred delivery city",
        accessor: (row) =>
          row.pref_delivery_city === null ? (
            <p style={{ color: "black" }}>Group Area</p>
          ) : (
            <p style={{ color: "black" }}>{row.pref_delivery_city}</p>
          ),
      },

      {
        Header: "Suspend Status",
        accessor: (row) => {
          if (row.report_status_count === 1) {
            return <Tag>Pending</Tag>;
          }
          if (row.report_counts === 0 && row.report_invalid_counts !== 0) {
            return <Tag>Report Submitted</Tag>;
          } else {
            if (row.report_counts === 1 && row.report_status_count === 0) {
              return (
                <>
                  <Tag>First Warning</Tag>
                </>
              );
            }
            if (row.report_counts === 2 && row.report_status_count === 0) {
              return (
                <>
                  <Tag>Second Warning</Tag>
                </>
              );
            }
            if (row.report_counts === 3 && row.report_status_count === 0) {
              return <Tag color="Red">Account Deactivated</Tag>;
            }
          }
        },
      },
      {
        Header: "Suspend Count",
        accessor: "report_counts",
      },
      {
        Header: "Application date",
        accessor: (row) => {
          const utcTime = moment.utc(row.date).tz("Asia/Manila");
          return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed
        },
      },

      {
        Header: "Delivered Count",
        accessor: "delivered_count",
      },
      {
        Header: "Referred Customer",
        accessor: "referred_customer_count",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchRiderInMyArea(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    if (reportLoading) {
      dispatch(fetchRiderInMyArea(defaultPage));
    }
  }, [dispatch, defaultPage, reportLoading]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={riderInMyAreaData} //
          loading={loading || reportLoading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={[
            <Button
              key="1"
              onClick={onAdd}
              className=""
              icon={<PlusOutlined />}
              type="primary"
            >
              Create Rider
            </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <ReportAccountModal
              partner_id={ids.partner_id}
              rider_id={ids.rider_id}
            />
            <CreateRiderModal
              changeCommission={changeCommission}
              setChangeCommission={setChangeCommission}
              riderDetails={riderDetails}
            />
          </>
        }
      />
    </>
  );
};

export default RiderInMyAreaPage;
