import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { AiFillWarning } from "react-icons/ai";
import { Button } from "antd";
import { createEmailAddress } from "../redux/actions/emailActions";

const CreateEmailAddress = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [userId] = useState(location.state?.userId || "");
  const [otpCode] = useState(location.state?.otpCode || "");
  const { register, handleSubmit } = useForm();

  const userInfo = useSelector((state) => state.userLoginReducer.userInfo);
  const emailState = useSelector((state) => state.emailReducer);

  const id = userInfo ? userInfo.id : null;

  const { loading, error } = emailState || { loading: false, error: null };

  const onSubmit = async (data) => {
    const payload = {
      email_address: data.email,
    };

    try {
      const response = await dispatch(
        createEmailAddress(payload, otpCode, userId || id)
      );

      if (
        response.message ===
        "Email address successfully updated. No reset link sent as no OTP was provided."
      ) {
        history.push("/partner-dashboard");
      } else if (
        response.message ===
        "Email address successfully updated and reset link sent."
      ) {
        history.push("/email-sent");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Submission error:", error.message);
    }
  };

  const errorMessage = error
    ? typeof error === "string"
      ? error
      : JSON.stringify(error)
    : null;

  return (
    <section className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="w-full max-w-sm p-8 mx-auto bg-white rounded-lg shadow-lg dark:bg-gray-800">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
          Save your Email Address
        </h1>

        <p className="text-gray-600 dark:text-gray-400 mb-6">
          To enhance security and add a "Forgot Password" feature, please enter
          your email address below to proceed.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {errorMessage && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
              role="alert"
            >
              <div className="flex items-center">
                <AiFillWarning className="text-xl mr-2" />
                <span>{errorMessage}</span>
              </div>
            </div>
          )}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-400"
              placeholder="example@example.com"
              ref={register({ required: true })}
            />
          </div>
          <Button
            type="primary"
            block
            htmlType="submit"
            size="large"
            className="bg-blue-600 hover:bg-blue-700"
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </form>
      </div>
    </section>
  );
};

export default CreateEmailAddress;
