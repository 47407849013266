import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Layout, Form, Input, Tag, Button, Popconfirm } from "antd";
import moment from "moment";

// Components
import { PlusCircleOutlined } from "@ant-design/icons";
import ServerSideTable from "../table/serverSideTable";
import AddMarkupPriceComponent from "../addMarkupPrice/addMarkupPriceComponent";
import { MyDrawer } from "../users/nationalMerchantSideDrawer";

// Actions
import { fetchSubOpsReferredRider } from "../../redux/actions/subOperatorReferralRider";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const SubOperatorMerchantTable = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [markupDrawer, setMarkUpDrawer] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState([]);
  const [drawerType, setDrawerType] = useState("");

  const {
    loading,
    subOpsreferredRiderData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(({ subOpsReferredRiderReducer }) => ({
    subOpsreferredRiderData: subOpsReferredRiderReducer.subOpsreferredRiderData,
    totalRecord: subOpsReferredRiderReducer.subOpsreferredRiderTotalRecord,
    nextPage: subOpsReferredRiderReducer.subOpsreferredRiderNextPage,
    previousPage: subOpsReferredRiderReducer.subOpsreferredRiderPreviousPage,
    currentPage: subOpsReferredRiderReducer.subOpsreferredRiderCurrentPage,
    loading: subOpsReferredRiderReducer.loading,
  }));

  const openMarkUpDrawer = (data) => {
    setMarkUpDrawer(true);
    setSelectedMerchant(data);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const closeMarkUpDrawer = () => {
    setMarkUpDrawer(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      role: "merchant",
    };
    dispatch(fetchSubOpsReferredRider(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      role: "merchant",
    };

    dispatch(fetchSubOpsReferredRider(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      role: "merchant",
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <Button size="small" type="text">
            <Popconfirm
              title="Mark up"
              onConfirm={() => {
                openMarkUpDrawer(original);
                setDrawerType("Add");
              }}
              okText="Add Mark Up"
              cancelText="Marked Up Products"
              onCancel={() => {
                openMarkUpDrawer(original);
                setDrawerType("View");
              }}
            >
              <PlusCircleOutlined />
            </Popconfirm>
          </Button>
        ),
      },
      {
        Header: "Merchant Name",
        accessor: "name",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Barangay",
        accessor: "barangay",
      },
      {
        Header: "Contact Number",
        accessor: "contact_number",
      },
      {
        Header: "Status",
        accessor: (row) => {
          if (row.isActive === 1) {
            return <Tag color="success">Active</Tag>;
          } else {
            return <Tag color="default">Inactive</Tag>;
          }
        },
      },
      {
        Header: "Registration Date",
        accessor: (row) => {
          return moment(row.created_at).format("MMMM D, YYYY");
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchSubOpsReferredRider(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={subOpsreferredRiderData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />

        <>
          <MyDrawer visible={drawerVisible} onClose={closeDrawer} />
          <AddMarkupPriceComponent
            visible={markupDrawer}
            onClose={closeMarkUpDrawer}
            data={selectedMerchant}
            Viewtype={drawerType}
            setDrawerType={setDrawerType}
          />
        </>
      </LayoutContent>
    );
  };

  return <>{renderContent()}</>;
};

export default SubOperatorMerchantTable;
