import React, { useState } from "react";
import { Modal, Button, Typography, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { toggleModalSubscription } from "../redux/actions/users";
import axiosInstance from "../helpers/axios";

const { Title, Paragraph } = Typography;
const SubscriptionModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { isModalSubscriptionClosable, shouldShowSubscriptionModal, userInfo } =
    useSelector(
      ({ userReducer, userLoginReducer }) => ({
        isModalSubscriptionClosable: userReducer.isModalSubscriptionClosable,
        shouldShowSubscriptionModal: userReducer.shouldShowSubscriptionModal,
        userInfo: userLoginReducer.userInfo,
      }),
      isEqual
    );

  const handleOk = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance(userInfo.token).post(
        "/api/sub-operator/subscription-payment-request",
        {
          sub_operator_id: userInfo.id,
        }
      );

      window.location.href = data.xendItLinkUrl;
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(">>>> Something weng wrong initializing payment request");
    }
  };
  const handleCancel = () => {
    dispatch(
      toggleModalSubscription({
        shouldShowModal: 0,
        closable: 1,
      })
    );
  };

  return (
    <>
      <Modal
        open={shouldShowSubscriptionModal}
        onOk={handleOk}
        onCancel={isModalSubscriptionClosable ? handleCancel : undefined}
        closable={false}
        footer={[
          isModalSubscriptionClosable === 1 && (
            <Button key="cancel" onClick={handleCancel}>
              Maybe Later
            </Button>
          ),
          <Button
            key="ok"
            type="primary"
            onClick={handleOk}
            loading={loading}
            disabled={loading}
          >
            Pay Now
          </Button>,
        ]}
      >
        {isModalSubscriptionClosable === 1 ? (
          <>
            <Alert
              message="Your subscription is about to expire!"
              description="To continue enjoying our services, please renew your subscription."
              type="warning"
              showIcon
            />
            <Paragraph style={{ marginTop: 16 }}>
              Click <strong>Pay Now</strong> to renew your subscription or{" "}
              <strong>Maybe Later</strong> to dismiss this message.
            </Paragraph>
          </>
        ) : (
          <>
            <Alert
              message="Your subscription has expired."
              description="You no longer have access to Dory Platform features."
              type="error"
              showIcon
            />
            <Paragraph style={{ marginTop: 16 }}>
              To regain access, please renew your subscription by clicking{" "}
              <strong>Pay Now</strong>.
            </Paragraph>
          </>
        )}
      </Modal>
    </>
  );
};

export default SubscriptionModal;
