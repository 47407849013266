import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Tooltip,
  Form,
  Input,
  Modal,
  DatePicker,
  Switch,
  Descriptions,
  Popconfirm,
  Drawer,
  Tag,
} from "antd";
import {
  FormOutlined,
  PlusOutlined,
  EyeOutlined,
  LockOutlined,
  ShopOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  CloseOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import Icon from "@mdi/react";
import { mdiFoodOutline, mdiTextBoxPlusOutline } from "@mdi/js";

import dayjs from "dayjs";
import { Link } from "react-router-dom";
// Components
import Wrapper from "../components/wrapper";
import UsersModal from "../components/users/modal";
// import AddMenuModal from "../components/users/addMenuModal";
import ServerSideTable from "../components/table/serverSideTable";
import NationalMerchants from "./nationalMerchants";
import { MyDrawer } from "../components/users/nationalMerchantSideDrawer";
// Actions
// import { toggleModal, setForm } from "../redux/actions/users";
import {
  fetchMerchants,
  toggleModal,
  setForm,
  toggleModalFeaturedImage,
  updatePartnerMerchantStatus,
  // toggleAddMenuModal,
  // setMenu,
  softDeletion,
} from "../redux/actions/partnerMerchants";
import AddFeaturedImageModal from "../components/users/addFeaturedImageModal";

import AddMarkupPriceComponent from "../components/addMarkupPrice/addMarkupPriceComponent";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const Users = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [viewMerchantsDetails, setViewMerchantsDetails] = useState(false);
  const [merchantsDetails, setMerchantsDetails] = useState("");
  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  // const [merchantId, setMerchantId] = useState("");
  // const [menuSubmitted, setMenuSubmitted] = useState(false);\
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);
  const [featuredImage, setFeaturedImage] = useState("");
  const [merchantId, setMerchantId] = useState("");

  // const [menuSubmitted, setMenuSubmitted] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activateLoading, setActivationLoading] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [markupDrawer, setMarkUpDrawer] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState([]);
  const [drawerType, setDrawerType] = useState("");
  const openMarkUpDrawer = (data) => {
    setMarkUpDrawer(true);
    setSelectedMerchant(data);
  };

  const closeMarkUpDrawer = () => {
    setMarkUpDrawer(false);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
    setRefreshTable(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const handleOk = () => {
    setViewMerchantsDetails(false);
  };

  const {
    loading,
    formLoading,
    merchantsData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    userInfo,
  } = useSelector(
    ({ merchantsReducer, userLoginReducer }) => ({
      merchantsData: merchantsReducer.data,
      totalRecord: merchantsReducer.totalRecord,
      nextPage: merchantsReducer.nextPage,
      previousPage: merchantsReducer.previousPage,
      currentPage: merchantsReducer.currentPage,
      loading: merchantsReducer.loading,
      formLoading: merchantsReducer.formLoading,
      menu: merchantsReducer.menu,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  useEffect(() => {
    if (!drawerVisible) {
      setActivationLoading(true);
    }
  }, [drawerVisible]);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setDelaySearchText(searchText);
  //   }, 300);
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [searchText]);

  useEffect(() => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    const i = {
      page: 1,
      limit: 15,
    };
    const payload = {
      page: 1,
      limit: 15,
      dateFilter:
        formatDate(selectedDate) === "Invalid Date"
          ? undefined
          : formatDate(selectedDate) + " " + "00:00:00",
      todateFilter:
        formatDate(toSelectedDate) === "Invalid Date"
          ? undefined
          : formatDate(toSelectedDate) + " " + "23:59:59",
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(fetchMerchants(payload));
    } else {
      dispatch(fetchMerchants(i));
    }
  }, [delaySearchText, selectedDate, dispatch, toSelectedDate]);

  const onAdd = () => dispatch(toggleModal());

  const onChange = (status, id) => {
    dispatch(updatePartnerMerchantStatus({ isActive: status, id }));
  };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchMerchants(payload));
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value === "") {
      const payload = {
        page: 1,
        limit: 15,
      };

      dispatch(fetchMerchants(payload));
    }
  };

  const onEdit = ({ data }) => {
    setChangePassword(false);
    dispatch(setForm(data));
    dispatch(toggleModal());
  };

  const softDelete = (payload) => {
    dispatch(softDeletion(payload));
  };

  const onClickFeaturedImage = ({ data }) => {
    setFeaturedImage(data.featured_image_url);
    setMerchantId(data.id);
    dispatch(toggleModalFeaturedImage());
  };

  const onAddMenu = ({ data }) => {
    // dispatch(setMenu(data.menu_text));
    // setMerchantId(data);
    setChangePassword(false);
    // dispatch(toggleAddMenuModal());
  };
  // const onView = ({ data }) => {
  //   setViewMerchantsDetails(true);
  //   dispatch(setForm(data));
  //   dispatch(toggleModal());
  // };
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate(dates[0]);

      setToSelectedDate(dates[1]);
    } else {
      setSelectedDate(null);
      setToSelectedDate(null);
    }
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Area Merchants</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={handleChange}
                onSearch={onSearch}
              />
            </Form.Item>

            <RangePicker
              style={{ width: "30vw" }}
              value={[selectedDate, toSelectedDate]}
              onChange={handleDateChange}
              placeholder={["Start Date", "End Date"]}
              format="YYYY-MM-DD"
            />
          </Form>
        </div>
      </div>
    );
  };

  // const extra = () => {
  //   const addButton = (
  //     <Button key="1" onClick={onAdd} className="mr-2">
  //       <PlusOutlined /> Add
  //     </Button>
  //   );

  //   return [addButton];
  // };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchMerchants({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchMerchants({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchMerchants({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchMerchants({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            {/* <Button
              disabled={formLoading}
              onClick={() => {
                onAddMenu({ data: original });
              }}
              size="small"
              type="text"
            >
              <Tooltip title="View Menu">
                <ShopOutlined />
              </Tooltip>
            </Button> */}

            {/* <Button
              disabled={formLoading}
              onClick={() => {
                onClickFeaturedImage({ data: original });
              }}
              size="small"
              type="text"
            >
              <Tooltip title="Edit Featured Image">
                <FileImageOutlined />
              </Tooltip>
            </Button> */}

            <Link
              to={`/merchantMenu/${original.id}/${original.name}/${original.merchant_type_id}`}
            >
              <Button disabled={formLoading} size="small" type="text">
                <Tooltip title="View Menu">
                  <span className="anticon anticon-check-square">
                    <Icon path={mdiFoodOutline} size={0.7} />
                  </span>
                </Tooltip>
              </Button>
            </Link>

            <Link to={`/add-ons/${original.id}/${original.name}`}>
              <Button disabled={formLoading} size="small" type="text">
                <Tooltip title="Add Ons">
                  <span className="anticon anticon-check-square">
                    <Icon path={mdiTextBoxPlusOutline} size={0.7} />
                  </span>
                </Tooltip>
              </Button>
            </Link>

            <Link to={`/merchantCategoryMenu/${original.id}/${original.name}`}>
              <Button disabled={formLoading} size="small" type="text">
                <Tooltip title="Menu Categories">
                  <MenuOutlined />
                </Tooltip>
              </Button>
            </Link>
            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>

            <Button
              disabled={formLoading}
              onClick={() => {
                setMerchantsDetails(original);
                setViewMerchantsDetails(true);
              }}
              size="small"
              type="text"
            >
              <Tooltip title="View details">
                <InfoCircleOutlined />
              </Tooltip>
            </Button>

            {/* <Button
              disabled={formLoading}
              onClick={() => {
                onEdit({ data: original });
                setChangePassword(true);
              }}
              size="small"
              type="text"
            >
              <Tooltip title="Change password">
                <LockOutlined />
              </Tooltip>
            </Button> */}

            {/* {original.merchant_type_id === 2 && (
              <>
                {userInfo.role === "operator_admin" && (
                  <Popconfirm
                    title="Delete a merchant"
                    description="If you delete this merchant, all of its menus and categories will also be deleted."
                    onConfirm={() => {
                      softDelete(original);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button disabled={formLoading} size="small" type="text">
                      <Tooltip title="Delete">
                        <CloseOutlined />
                      </Tooltip>
                    </Button>
                  </Popconfirm>
                )}
              </>
            )} */}

            <Button size="small" type="text">
              <Popconfirm
                title="Mark up"
                onConfirm={() => {
                  openMarkUpDrawer(original);
                  setDrawerType("Add");
                }}
                okText="Add Mark Up"
                cancelText="Marked Up Products"
                onCancel={() => {
                  openMarkUpDrawer(original);
                  setDrawerType("View");
                }}
              >
                <PlusCircleOutlined />
              </Popconfirm>
            </Button>

            <Switch
              size="small"
              defaultChecked={original.isActive}
              onChange={(checked) => {
                onChange(checked, original.id);
              }}
            />
          </>
        ),
        width: 100,
      },
      {
        Header: "Store",
        accessor: "name",
      },
      // {
      //   Header: "User name",
      //   accessor: "username",
      // },

      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Barangay",
        accessor: "barangay",
      },
      {
        Header: "Contact Number",
        accessor: "contact_number",
      },
      // {
      //   Header: "Latitude",
      //   accessor: "latitude",
      // },
      // {
      //   Header: "Longitude",
      //   accessor: "longitude",
      // },
      {
        Header: "Date Created",
        accessor: "created_at",
        Cell: ({ value }) => {
          return dayjs(value).format("YYYY-MM-DD");
        },
      },
      {
        Header: "Merchant Type",
        accessor: (row) => {
          if (row.merchant_type_id === 2) {
            return (
              <Tag color="blue" style={{ fontSize: 14 }}>
                national
              </Tag>
            );
          } else {
            return (
              <Tag color="green" style={{ fontSize: 14 }}>
                local
              </Tag>
            );
          }
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchMerchants(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    if (activateLoading) {
      dispatch(fetchMerchants(defaultPage));
    }
    setActivationLoading(false);
  }, [dispatch, defaultPage, activateLoading]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={merchantsData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[
            // <Button key="1" onClick={onAdd} className="mr-2">
            //   <PlusOutlined /> Add Merchants
            // </Button>,
            <div>
              <Button type="primary" onClick={showDrawer}>
                Activate national merchants
              </Button>
            </div>,
          ]}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
        />
        {userInfo.role === "operator_admin" && (
          <>
            <MyDrawer visible={drawerVisible} onClose={closeDrawer} />
            <AddMarkupPriceComponent
              visible={markupDrawer}
              onClose={closeMarkUpDrawer}
              data={selectedMerchant}
              Viewtype={drawerType}
              setDrawerType={setDrawerType}
            />
          </>
        )}
      </LayoutContent>
    );
  };

  const items = [
    {
      key: "1",
      label: "Name",
      children: merchantsDetails.name,
    },
    {
      key: "2",
      label: "Contact Number",
      children: merchantsDetails.contact_number,
    },
    {
      key: "3",
      label: "Barangay",
      children: merchantsDetails.barangay,
      span: 2,
    },
    {
      key: "4",
      label: "City",
      children: merchantsDetails.city,
    },
    {
      key: "5",
      label: "Province",
      children: merchantsDetails.province,
    },
  ];

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            {/* <AddMenuModal
              merchant={merchantId}
              setMerchantId={setMerchantId}
              setMenuSubmitted={setMenuSubmitted}
              menuSubmitted={menuSubmitted}
            /> */}
            <AddFeaturedImageModal
              url={featuredImage}
              merchantId={merchantId}
            />
            <UsersModal
              changePassword={changePassword}
              setChangePassword={setChangePassword}
            />
            <>
              <Modal
                width={500}
                open={viewMerchantsDetails}
                onOk={handleOk}
                closable={false}
                footer={[
                  <Button key="submit" type="primary" onClick={handleOk}>
                    OK
                  </Button>,
                ]}
              >
                <Descriptions
                  title="MERCHANT DETAILS"
                  bordered
                  items={items}
                  size={"medium"}
                  column={1}
                />
                {/* <p>Name: {merchantsDetails.name}</p>
                <p>Username: {merchantsDetails.username}</p>
                <p>Barangay: {merchantsDetails.barangay}</p>
                <p>City: {merchantsDetails.city}</p>
                <p>Province: {merchantsDetails.province}</p> */}
              </Modal>
            </>
          </>
        }
      />
    </>
  );
};

export default Users;
