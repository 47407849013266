import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AiFillWarning } from "react-icons/ai";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { login as operatorAdminLogin } from "../redux/actions/opeatorAdmin";

const OperatorAdminSignin = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, loading } = useSelector((state) => state.userLoginReducer);

  const { register, handleSubmit, errors } = useForm();

  // const handleSignin = async (data) => {
  //   const loginResponse = await dispatch(operatorAdminLogin(data));
  //   if (loginResponse !== undefined) {
  //     if (loginResponse.role === "sub_operator") {
  //       history.push(
  //         location.search ? location.search.split("=")[1] : "/referral"
  //       );
  //       try {
  //         const response = await dispatch(operatorAdminLogin(data));
  //         if (response?.needsEmail) {
  //           history.push(`/create-email/${response.id}`);
  //         } else if (response?.error) {
  //           console.error("Login failed:", response.error);
  //         } else {
  //           history.push(
  //             location.search
  //               ? location.search.split("=")[1]
  //               : "/partner-dashboard"
  //           );
  //         }
  //       } catch (error) {
  //         console.error("An error occurred during login:", error);
  //       }
  //     }
  //   }
  // };

  const handleSignin = async (data) => {
    const loginResponse = await dispatch(operatorAdminLogin(data));
    if (loginResponse !== undefined) {
      if (loginResponse.role === "sub_operator") {
        history.push(
          location.search ? location.search.split("=")[1] : "/referral"
        );
      } else {
        history.push(
          location.search ? location.search.split("=")[1] : "/partner-dashboard"
        );
      }
    }
  };

  const navigateToForgotPassword = () => {
    history.push("/forgot-password");
  };

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-4xl font-semibold"
            style={{ color: "#38a34a" }}
          >
            Dory Platform
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form
                onSubmit={handleSubmit(handleSignin)}
                className="space-y-4 md:space-y-6"
                action="#"
              >
                {error && (
                  <div
                    className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <div className="flex flex-row">
                      <AiFillWarning className="mt-1" />
                      <span className="block sm:inline ml-2">{error}</span>
                    </div>
                  </div>
                )}
                <div>
                  <label
                    htmlFor="username"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your username
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="supremo"
                    ref={register({ required: true })}
                    pattern="\S+"
                    title="Please check if there is a space in your User Name"
                  />

                  {errors.username && (
                    <span className="italic leading-10 text-red-500">
                      This field is required
                    </span>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    pattern="\S+"
                    title="Please check if there is a space in your Password"
                    ref={register({ required: true })}
                  />
                  {errors.password && (
                    <span className="italic leading-10 text-red-500">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    size="large"
                    disabled={loading}
                    loading={loading}
                    style={{ marginBottom: 15 }}
                  >
                    Sign in
                  </Button>
                </div>
                <div className="text-right">
                  <a
                    href="#"
                    className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                    onClick={navigateToForgotPassword}
                  >
                    Forgot password?
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OperatorAdminSignin;
