import actions from "../actionTypes";
const { RIDEROPERATORREFERRAL } = actions;

const userInitialState = {
  loading: false,
  subOpsreferredRiderData: [],
  subOpsreferredRiderTotalRecord: 0,
  subOpsreferredRiderNextPage: undefined,
  subOpsreferredRiderPreviousPage: undefined,
  subOpsreferredRiderCurrentPage: undefined,
  error: null,
};

export const subOpsReferredRiderReducer = (
  state = userInitialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case RIDEROPERATORREFERRAL.FETCH.REQUEST:
      return { ...state, loading: true };
    case RIDEROPERATORREFERRAL.FETCH.SUCCESS:
      return {
        ...state,
        subOpsreferredRiderData: payload.data.data.result,
        subOpsreferredRiderTotalRecord: payload.data.count,
        subOpsreferredRiderNextPage: payload.data?.data?.next,
        subOpsreferredRiderPreviousPage: payload.data?.data?.previous,
        subOpsreferredRiderCurrentPage: payload.data?.currentPage,
        loading: false,
      };
    case RIDEROPERATORREFERRAL.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case RIDEROPERATORREFERRAL.SET:
      return {
        ...state,
        subOpsreferredRiderData: [
          ...state.subOpsreferredRiderData,
          payload.data.data,
        ],
      };

    case RIDEROPERATORREFERRAL.UPDATE:
      const riderData = payload.data.result;
      const currRidersList = state.subOpsreferredRiderData;

      const updatedRidersList = currRidersList.map((rider) =>
        rider.user_id === riderData.rider_user_id
          ? { ...rider, rider_commission_percentage: riderData.commission } // Merge existing rider with new data
          : rider
      );

      return {
        ...state,
        subOpsreferredRiderData: updatedRidersList,
      };

    default:
      return state;
  }
};
