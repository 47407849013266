import actionTypeCreator, { SYNC, ASYNC } from "redux-action-types-creator";

const actions = actionTypeCreator("EENT");

// user login
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

// user registration
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const PAGE_CURRENT = "PAGE_CURRENT";

export default actions({
  USER: {
    FETCH: ASYNC,
    FETCHSINGLE: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
    SETCLIENT: SYNC,
    CUSTOMUPDATE: SYNC,
    DELETEACCOUNT: ASYNC,
    FETCHREFERRALLINK: ASYNC,
    TOGGLESUBSCRIPTIONMODAL: SYNC,
  },

  MERCHANTS: {
    CLEARERROR: ASYNC,
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    TOGGLEMENUMODAL: SYNC,
    SET: SYNC,
    SETMENU: SYNC,
    FETCHTAGS: ASYNC,
    TOGGLEFEATUREDIMAGE: SYNC,
    CREATEFEATUREDIMAGE: ASYNC,
    SOFTDELETION: ASYNC,
  },

  STORES: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },

  STORESPRODUCTS: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
    TOGGLEIMPORT: SYNC,
    TOGGLENOSKU: SYNC,
    SETDATANOSKU: SYNC,
  },

  RAFFLETICKETS: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },

  ACCOUNT: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },
  ALLPRODUCTS: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },

  MERCHANTCATEGORY: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
    UPDATESTATUS: ASYNC,
    SOFTDELETION: ASYNC,
  },
  NATIONALMERCHANTMENU: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    CREATEMERCHANT: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
    TOGGLEDUPLICATE: SYNC,
    SOFTDELETION: ASYNC,
  },

  NATIONALMERCHANTCATEGORY: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
    UPDATESTATUS: ASYNC,
    SOFTDELETION: ASYNC,
  },
  MERCHANTMENU: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
    TOGGLEDUPLICATE: SYNC,
    SOFTDELETION: ASYNC,
  },

  OPERATORPERFORMANCE: {
    FETCH: ASYNC,
  },
  OPERATORPAYABLES: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  FRANCHISEEPAYABLES: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  MONITORINGPAYALBES: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  PAYABLESSTATUS: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  ALLFRANCHISEEPAYABLES: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  MANAGEOPERATOR: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
    CREATE: ASYNC,
    TOGGLE: SYNC,
    SET: SYNC,
    SOFTDELETION: ASYNC,
  },
  OPERATORPRICEAPPROVAL: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  NOTIFICATION: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },
  BOOKINGS: {
    FETCH: ASYNC,
    FETCHOPERATORS: ASYNC,
    FETCHOAVAILABLERIDERS: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
  },
  COUPONS: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },
  PARTNERCOUPONS: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },
  CUSTOMERS: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
    FETCHFILTERCITY: ASYNC,
    UPDATEACCESS: ASYNC,
  },
  OPERATORWITHDRAW: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  MERCHANTWITHDRAW: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  APPEARNINGWITHDRAW: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
  },
  ACCOUNTSRECEIVABLE: {
    FETCH: ASYNC,
  },
  MERCHANTTAGS: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },
  FIXRATE: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },
  GROUPEDAREAS: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },
  ADMINACCOUNTS: {
    TOGGLE: SYNC,
    SET: SYNC,
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    UPDATE_CONTACT_NUMBER: ASYNC,
  },
  CREATE_EMAIL: {
    REQUEST: ASYNC,
    SUCCESS: SYNC,
    FAILURE: SYNC,
  },
  PAYABLESINFO: {
    FETCH: ASYNC,
  },
  PAYABLESTATUSINFO: {
    FETCH: ASYNC,
  },
  SUCCEEDINGRATE: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },
  DELIVERYFEESETTINGS: {
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
  },

  PARTNERDASHBOARD: {
    FETCHTRANSACTION: ASYNC,
    FETCHCOLLECTEDDELIVERYFEE: ASYNC,
    FETCHTOPMERCHANTS: ASYNC,
    FETCHCUSTOMERGROWTH: ASYNC,
  },
  ADMINDASHBOARD: {
    FETCHTRANSACTION: ASYNC,
    FETCHTOPPARTNERS: ASYNC,
    FETCHTOPMERCHANTS: ASYNC,
    FETCHCUSTOMERGROWTH: ASYNC,
    FETCHTOPAREAS: ASYNC,
  },
  RIDER: {
    FETCHRIDERINMYAREA: ASYNC,
    FETCHREFERREDRIDER: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    SET: SYNC,
    CREATE: ASYNC,
    TOGGLECREATERIDER: SYNC,
    SETRIDERFORM: SYNC,
    CREATERIDER: ASYNC,
    UPDATECOMMISSION: ASYNC,
  },
  LOCALMERCHANTS: {
    FETCHLOCALMERCHANTINMYAREA: ASYNC,
    FETCHREFERREDLOCALMERCHANT: ASYNC,
  },
  RIDERPACKAGE: {
    FETCH: ASYNC,
  },

  NATIONALMERCHANTS: {
    CLEARERROR: ASYNC,
    FETCH: ASYNC,
    CREATE: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    TOGGLEVIEW: SYNC,
    TOGGLEMENUMODAL: SYNC,
    SET: SYNC,
    SETMENU: SYNC,
    FETCHTAGS: ASYNC,
    TOGGLEFEATUREDIMAGE: SYNC,
    CREATEFEATUREDIMAGE: ASYNC,
    SOFTDELETION: ASYNC,
  },
  MERCHANTSAPPROVAL: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    SET: SYNC,
    TOGGLEOLDACCOUNTMODAL: SYNC,
  },
  PARTNERFINANCE: {
    FETCHALLCOMMISSION: ASYNC,
    FETCHTOTALBALANCE: ASYNC,
    FETCH: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    SET: SYNC,
    TOGGLEOLDACCOUNTMODAL: SYNC,
  },
  VALIDATEREPORTEDRIDERS: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    SET: SYNC,
    CREATE: ASYNC,
  },
  MERCHANTSMENUAPPROVAL: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
    TOGGLE: SYNC,
    SET: SYNC,
  },
  REFERREDCUSTOMERS: {
    FETCH: ASYNC,
  },
  NEWRIDERS: {
    FETCH: ASYNC,
  },
  RIDERSDOCUMENTS: {
    FETCH: ASYNC,
  },

  RIDER_PACKAGES: {
    FETCH: ASYNC,
    FETCHSINGLE: ASYNC,
    SENDPACKAGE: ASYNC,
    TOGGLE: SYNC,
    SET: SYNC,
  },

  REPORTEDBOOKINGS: {
    FETCH: ASYNC,
    UPDATE: ASYNC,
    SET: ASYNC,
    TOGGLE: ASYNC,
  },

  RIDEROPERATORREFERRAL: {
    FETCH: ASYNC,
    SET: SYNC,
    UPDATE: ASYNC,
  },

  AFFILIATES: {
    FETCH: ASYNC,
    FETCHAREAAFFILIATE: ASYNC,
  },
  REFERREDOPERATOR: {
    FETCH: ASYNC,
  },
  REFERREDSUBOPERATOR: {
    FETCH: ASYNC,
  },
  ADDONS: {
    FETCHGROUP: ASYNC,
    CREATEGROUP: ASYNC,
    UPDATEGROUP: ASYNC,
    TOGGLEMODALADDONSGROUP: SYNC,
    SETGROUP: SYNC,

    FETCHCHOICES: ASYNC,
    CREATECHOICES: ASYNC,
    UPDATECHOICES: ASYNC,
    TOGGLEMODALADDONSCHOICES: SYNC,
    SETCHOICES: SYNC,
  },
});
