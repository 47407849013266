import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input, Badge, Switch } from "antd";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import Icon from "@mdi/react";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import AddOnsChoiceModal from "../components/add-ons/modalChoices";
// Actions
import {
  fetchChoices,
  toggleModalChoices,
  setFormChoices,
  updateAddOnsChoices,
} from "../redux/actions/AddOns";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const AddOnsChoices = () => {
  const location = useLocation();
  const state = location.state || {};

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [changeAddOnsChoices, setChangeAddOnsChoices] = useState(false);

  const {
    loadingChoices,
    formLoadingChoices,
    dataChoices,
    totalRecordChoices,
    nextPageChoices,
    previousPageChoices,
    currentPageChoices,
  } = useSelector(
    ({ addOnsReducers }) => ({
      dataChoices: addOnsReducers.dataChoices,
      totalRecordChoices: addOnsReducers.totalRecordChoices,
      nextPageChoices: addOnsReducers.nextPageChoices,
      previousPageChoices: addOnsReducers.previousPageChoices,
      currentPageChoices: addOnsReducers.currentPageChoices,
      loadingChoices: addOnsReducers.loadingChoices,
      formLoadingChoices: addOnsReducers.formLoadingChoices,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      add_ons_id: state.id,
      isNational: state.isNational,
    };
    dispatch(fetchChoices(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => {
    dispatch(toggleModalChoices());
  };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      add_ons_id: state.id,
      isNational: state.isNational,
    };

    dispatch(fetchChoices(payload));
  };

  const onEdit = ({ data }) => {
    setChangeAddOnsChoices(true);
    dispatch(setFormChoices(data));
    dispatch(toggleModalChoices());
  };

  const onChange = (status, id) => {
    dispatch(
      updateAddOnsChoices({
        toggle: true,
        isActive: status,
        add_ons_choices_id: id,
        isNational: state.isNational,
      })
    );
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>
          {state.merchant_name} - {state.name} - Choices
        </h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      add_ons_id: state.id,
      isNational: state.isNational,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchChoices({
        ...nextPageChoices,
        searchText,
        add_ons_id: state.id,
        isNational: state.isNational,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchChoices({
        ...previousPageChoices,
        searchText,
        add_ons_id: state.id,
        isNational: state.isNational,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchChoices({
        ...defaultPage,
        searchText,
        add_ons_id: state.id,
        isNational: state.isNational,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchChoices({
        page: Math.ceil(totalRecordChoices / 15),
        limit: 15,
        searchText,
        add_ons_id: state.id,
        isNational: state.isNational,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            {/* edit */}
            <Button
              disabled={formLoadingChoices}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>

            {/* swtich */}

            <Tooltip title="Update status">
              <Switch
                size="small"
                defaultChecked={original.isActive}
                onChange={(checked) => {
                  onChange(checked, original.id);
                }}
              />
            </Tooltip>
          </>
        ),
        width: 100,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Status",
        accessor: (row) =>
          row.isActive ? (
            <Badge status="success" text="Active" />
          ) : (
            <Badge status="error" text="Inactive" />
          ),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchChoices(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={dataChoices}
          loading={loadingChoices}
          serverSidePagination={{
            totalRecord: totalRecordChoices,
            onClickNextPage,
            onClickPreviousPage,
            currentPage: currentPageChoices,
            nextPage: nextPageChoices,
            previousPage: previousPageChoices,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <AddOnsChoiceModal
              setChangeAddOnsChoices={setChangeAddOnsChoices}
              addOnsGroupId={state.id}
              isNational={state.isNational}
            />
          </>
        }
      />
    </>
  );
};

export default AddOnsChoices;
