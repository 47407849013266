import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { ADDONS } = actions;

export const toggleModalGroup =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: ADDONS.TOGGLEMODALADDONSGROUP, payload });
  };

export const setFormGroup = (payload) => (dispatch) => {
  dispatch({ type: ADDONS.SETGROUP, payload });
};

export const createGroup = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADDONS.CREATEGROUP.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      "/api/merchants/menu/add-ons-group",
      payload
    );
    dispatch({ type: ADDONS.CREATEGROUP.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ADDONS.CREATEGROUP.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const fetchGroups = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADDONS.FETCHGROUP.REQUEST });
  const { page, limit, searchText } = payload;

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;

  try {
    let url = `/api/add-ons/${payload.merchant_id}?page=${page}&limit=${limit}&isNational=${payload.isNational}`;

    if (payload !== undefined) {
      url = `/api/add-ons/${payload.merchant_id}?page=${page}&limit=${limit}&isNational=${payload.isNational}`;
      if (page && limit && searchText) {
        url = `/api/add-ons/${payload.merchant_id}?page=${page}&limit=${limit}&searchText=${searchText}&isNational=${payload.isNational}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: ADDONS.FETCHGROUP.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: ADDONS.FETCHGROUP.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const updateAddOnsGroup = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADDONS.UPDATEGROUP.REQUEST });

  const { toggle } = payload;
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;

  try {
    let url = "/api/merchants/menu/add-ons-group";

    if (toggle) {
      url = "/api/merchants/menu/add-ons-group?toggle=true";
    }

    const response = await axiosInstance(token).patch(url, payload);

    dispatch({
      type: ADDONS.UPDATEGROUP.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: ADDONS.UPDATEGROUP.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const toggleModalChoices =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: ADDONS.TOGGLEMODALADDONSCHOICES, payload });
  };

export const setFormChoices = (payload) => (dispatch) => {
  dispatch({ type: ADDONS.SETCHOICES, payload });
};

export const fetchChoices = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADDONS.FETCHCHOICES.REQUEST });
  const { page, limit, searchText } = payload;

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;

  try {
    let url = `/api/add-ons/choices/${payload.add_ons_id}?page=${page}&limit=${limit}&isNational=${payload.isNational}`;

    if (payload !== undefined) {
      url = `/api/add-ons/choices/${payload.add_ons_id}?page=${page}&limit=${limit}&isNational=${payload.isNational}`;
      if (page && limit && searchText) {
        url = `/api/add-ons/choices/${payload.add_ons_id}?page=${page}&limit=${limit}&searchText=${searchText}&isNational=${payload.isNational}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: ADDONS.FETCHCHOICES.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: ADDONS.FETCHCHOICES.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const createChoice = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADDONS.CREATECHOICES.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).post(
      "/api/merchants/menu/add-ons-choices",
      payload
    );

    dispatch({ type: ADDONS.CREATECHOICES.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ADDONS.CREATECHOICES.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const updateAddOnsChoices = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADDONS.UPDATECHOICES.REQUEST });

  const { toggle } = payload;
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;

  try {
    let url = "/api/merchants/menu/add-ons-choices";

    if (toggle) {
      url = "/api/merchants/menu/add-ons-choices?toggle=true";
    }

    const response = await axiosInstance(token).patch(url, payload);

    dispatch({
      type: ADDONS.UPDATECHOICES.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: ADDONS.UPDATECHOICES.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
