import React, { useState } from "react";
import { Button, Modal } from "antd";
import { AiFillWarning } from "react-icons/ai";
import axiosInstance from "../helpers/axios";
import { useHistory } from "react-router-dom";

const SendEmailResetPassword = () => {
  const [contact, setContact] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");

    if (!contact) {
      setErrorMessage("Please enter your contact number or email.");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance().post(
        `/api/admin-account/verify-phone-number/${contact}`
      );

      if (response.data.success === true) {
        history.push({
          pathname: "/sending-otp",
          state: {
            contact: contact,
            token: response.data.token,
            userId: response.data.userId,
          },
        });
      } else {
        history.push("/partner-signin");
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message ||
            "An unexpected error occurred. Please try again later."
        );
      } else {
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // const handleModalOk = () => {
  //   setModalVisible(false);
  //   history.push("/sending-otp");
  // };

  // const handleModalCancel = () => {
  //   setModalVisible(false);
  // };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-4xl font-semibold"
          style={{ color: "#38a34a" }}
        >
          Dory Platform
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Reset Password
            </h1>

            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              {errorMessage && (
                <div
                  className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <div className="flex flex-row">
                    <AiFillWarning className="mt-1" />
                    <span className="block sm:inline ml-2">{errorMessage}</span>
                  </div>
                </div>
              )}

              <div>
                <label
                  htmlFor="contact"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Please enter the mobile number associated with your account.
                </label>
                <input
                  type="text"
                  name="contact"
                  id="contact"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="09123456789"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </div>

              <div className="flex items-center justify-between">
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  style={{ marginBottom: 15 }}
                >
                  Send OTP
                </Button>
              </div>
            </form>

            {/* <Modal
              title="Email Address Not Found"
              open={modalVisible}
              onOk={handleModalOk}
              onCancel={handleModalCancel}
              okText="Got it"
              cancelButtonProps={{ style: { display: 'none' } }} // Hide cancel button
            >
              <p>You don't have an email address associated with this contact number. An OTP has been sent to you.</p>
            </Modal> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendEmailResetPassword;
