import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";

import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Radio,
  Select,
  Input,
  InputNumber,
  Switch,
} from "antd";

// Actions
import {
  toggleModalGroup,
  setFormGroup,
  createGroup,
  updateAddOnsGroup,
} from "../../redux/actions/AddOns";

// Utilities
import axiosInstance from "../../helpers/axios";

const AddOnsGroupModal = ({
  setChangeAddOnsGroup,
  merchant_id,
  isNational = false,
}) => {
  const dispatch = useDispatch();

  const [radioValue, setRadioValue] = useState("add_ons");
  const [groupTitle, setGroupTitle] = useState([]);
  const [title, setTitle] = useState("");
  const [maxSelection, setMaxSelection] = useState(1);
  const [isRequired, setisRequired] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const { modalGroup, formLoadingGroup, errorGroup, formGroup, userInfo } =
    useSelector(
      ({ addOnsReducers, userLoginReducer }) => ({
        modalGroup: addOnsReducers.modalGroup,
        formLoadingGroup: addOnsReducers.formLoadingGroup,
        errorGroup: addOnsReducers.errorGroup,
        formGroup: addOnsReducers.formGroup,
        userInfo: userLoginReducer.userInfo,
      }),
      isEqual
    );

  const hasForm = formGroup ? Object.keys(formGroup).length !== 0 : false;

  useEffect(() => {
    if (hasForm) {
      console.log(">>>> formGroup.is_required:", formGroup.is_required);

      setTitle(formGroup.name);
      setRadioValue(formGroup.type);
      setMaxSelection(parseFloat(formGroup.max_selection));
      setisRequired(formGroup.is_required ? true : false);
    }
  }, [
    formGroup.is_required,
    formGroup.max_selection,
    formGroup.name,
    formGroup.type,
    hasForm,
  ]);

  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const { data } = await axiosInstance(userInfo?.token).get(
          `/api/merchants/menu/add-on-group-title?merchant_id=${merchant_id}`
        );

        setGroupTitle(data.result);
      } catch (error) {
        console.log(
          ">>> something went wrong while fetching add ons group title"
        );
      }
    };

    fetchTitle();
  }, []);

  const onChange = (e) => {
    setTitle("");
    setRadioValue(e.target.value);
  };

  const onChangeSelect = (value) => {
    setTitle(value);
  };

  const close = () => {
    dispatch(toggleModalGroup(false));
    dispatch(setFormGroup({}));
    setChangeAddOnsGroup(false);
    setRadioValue("add_ons");
    setTitle("");
    setMaxSelection(1);
    setisRequired(false);
    setTitleError(false);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async () => {
    try {
      setTitleError(false);

      if (title === "") {
        setTitleError(true);
        return;
      }

      if (hasForm) {
        const newData = {
          name: title,
          isActive: formGroup.isActive,
          is_required: isRequired,
          type: radioValue,
          add_on_group_id: formGroup.id,
          max_selection: maxSelection,
          isNational,
        };

        await dispatch(updateAddOnsGroup(newData));

        close();
        return openNotification("SUCCESS", "Succesuffyl Updated Add Ons Group");
      } else {
        const newData = {
          name: title,
          partner_merchants_id: merchant_id,
          is_required: isRequired,
          type: radioValue,
          max_selection: maxSelection,
          isNational,
        };

        const { data } = await dispatch(createGroup(newData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("updating/creating add ons group error:>>", error);
    }
  };

  useEffect(() => {
    if (errorGroup) openNotification("ERROR", errorGroup.message);
  }, [errorGroup]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modalGroup}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={onSubmit}
      >
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <p style={{ fontWeight: "bold", margin: 0 }}>Type</p>
              <Radio.Group onChange={onChange} value={radioValue}>
                <Radio value={"add_ons"}>
                  <p
                    style={{
                      fontWeight: "bold",
                      display: "inline-block",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    Add-Ons{" "}
                  </p>{" "}
                  (e.g. Drinks, Dessert)
                </Radio>
                <Radio value={"preference"}>
                  <p
                    style={{
                      fontWeight: "bold",
                      display: "inline-block",
                      marginBottom: 0,
                      padding: 0,
                    }}
                  >
                    Preference
                  </p>{" "}
                  (e.g. Flavor, Spice Level)
                </Radio>
              </Radio.Group>
            </Col>
            <Col
              className="gutter-row"
              span={24}
              style={{ width: "100%", marginTop: 10 }}
            >
              <p style={{ fontWeight: "bold", margin: 0, padding: 0 }}>Title</p>
              {radioValue === "add_ons" ? (
                <>
                  <Select
                    value={title}
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select add-ons title"
                    optionFilterProp="label"
                    onChange={onChangeSelect}
                    options={groupTitle}
                  />
                  {titleError && (
                    <p style={{ padding: 0, margin: 0, color: "red" }}>
                      Title is required
                    </p>
                  )}
                </>
              ) : (
                <>
                  <Input
                    defaultValue={title}
                    placeholder="Preference title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {titleError && (
                    <p style={{ padding: 0, margin: 0, color: "red" }}>
                      Title is required
                    </p>
                  )}
                </>
              )}
            </Col>
            <Col
              className="gutter-row"
              span={24}
              style={{ width: "100%", marginTop: 10 }}
            >
              <p style={{ padding: 0, margin: 0, fontWeight: "bold" }}>
                Maximum Selection
              </p>
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                defaultValue={1}
                value={maxSelection}
                onChange={setMaxSelection}
              />
            </Col>
            <Col
              className="gutter-row"
              span={24}
              style={{ width: "100%", marginTop: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 10,
                }}
              >
                <p style={{ fontWeight: "bold", marginBottom: 0 }}>Required?</p>
                <Switch
                  size="small"
                  checked={isRequired}
                  onChange={setisRequired}
                />
              </div>
            </Col>
          </Row>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoadingGroup}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddOnsGroupModal;
