import actions from "../actionTypes";
const { ADDONS } = actions;

const addOnsInitialState = {
  // add ons group
  modalGroup: false,
  formLoadingGroup: false,
  dataGroup: [],
  formGroup: {},
  loadingGroup: false,
  errorGroup: null,
  totalRecordGroup: 0,
  nextPageGroup: undefined,
  previousPageGroup: undefined,
  currentPageGroup: undefined,

  // add ons choices
  modalChoices: false,
  formLoadingChoices: false,
  dataChoices: [],
  formChoices: {},
  loadingChoices: false,
  errorChoices: null,
  totalRecordChoices: 0,
  nextPageChoices: undefined,
  previousPageChoices: undefined,
  currentPageChoices: undefined,
};

export const addOnsReducers = (state = addOnsInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADDONS.TOGGLEMODALADDONSGROUP:
      return { ...state, modalGroup: payload, error: null };

    case ADDONS.SETGROUP:
      return { ...state, formGroup: payload };

    case ADDONS.CREATEGROUP.REQUEST:
      return { ...state, formLoadingGroup: true };

    case ADDONS.CREATEGROUP.SUCCESS:
      const newData = payload;

      return {
        ...state,
        formLoadingGroup: false,
        dataGroup: [...state.dataGroup, newData.data.result],
      };
    case ADDONS.CREATEGROUP.FAILURE:
      return { ...state, formLoadingGroup: false, errorGroup: payload };

    case ADDONS.FETCHGROUP.REQUEST:
      return { ...state, loadingGroup: true };
    case ADDONS.FETCHGROUP.SUCCESS:
      return {
        ...state,
        dataGroup: payload.data.data.result,
        totalRecordGroup: payload.data.count,
        nextPageGroup: payload.data?.data?.next,
        previousPageGroup: payload.data?.data?.previous,
        currentPageGroup: payload.data?.currentPage,
        loadingGroup: false,
      };
    case ADDONS.FETCHGROUP.FAILURE:
      return { ...state, errorGroup: payload, loadingGroup: false };

    case ADDONS.UPDATEGROUP.REQUEST:
      return { ...state, formLoadingGroup: true };
    case ADDONS.UPDATEGROUP.SUCCESS:
      const updatedData = payload.data.result;

      let currentData = [...state.dataGroup];

      currentData = currentData.map((a) =>
        a.id === updatedData.id ? updatedData : a
      );

      return {
        ...state,
        formLoadingGroup: false,
        formGroup: {},
        dataGroup: currentData,
      };

    case ADDONS.UPDATEGROUP.FAILURE:
      return { ...state, formLoadingGroup: false, errorGroup: payload };

    //   add ons choices

    case ADDONS.TOGGLEMODALADDONSCHOICES:
      return { ...state, modalChoices: payload, errorChoices: null };

    case ADDONS.SETCHOICES:
      return { ...state, formChoices: payload };

    case ADDONS.CREATECHOICES.REQUEST:
      return { ...state, formLoadingChoices: true };

    case ADDONS.CREATECHOICES.SUCCESS:
      const newDataChoice = payload;

      return {
        ...state,
        formLoadingChoices: false,
        dataChoices: [...state.dataChoices, newDataChoice.data.result],
      };
    case ADDONS.CREATECHOICES.FAILURE:
      return { ...state, formLoadingChoices: false, errorChoices: payload };

    case ADDONS.FETCHCHOICES.REQUEST:
      return { ...state, loadingChoices: true };
    case ADDONS.FETCHCHOICES.SUCCESS:
      return {
        ...state,
        dataChoices: payload.data.data.result,
        totalRecordChoices: payload.data.count,
        nextPageChoices: payload.data?.data?.next,
        previousPageChoices: payload.data?.data?.previous,
        currentPageChoices: payload.data?.currentPage,
        loadingChoices: false,
      };
    case ADDONS.FETCHCHOICES.FAILURE:
      return { ...state, errorChoices: payload, loadingChoices: false };

    case ADDONS.UPDATECHOICES.REQUEST:
      return { ...state, formLoadingChoices: true };
    case ADDONS.UPDATECHOICES.SUCCESS:
      const updatedChoice = payload.data.result;

      let currentChoices = [...state.dataChoices];

      currentChoices = currentChoices.map((a) =>
        a.id === updatedChoice.id ? updatedChoice : a
      );

      return {
        ...state,
        formLoadingChoices: false,
        formChoices: payload.data.data,
        dataChoices: currentChoices,
      };

    case ADDONS.UPDATECHOICES.FAILURE:
      return { ...state, formLoadingChoices: false, errorChoices: payload };

    default:
      return state;
  }
};
