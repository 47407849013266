// hooks
import React, { useEffect, useState, useCallback, useMemo } from "react";

// redux
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

// components
import { Button, Drawer, Typography, Card, Spin, Descriptions } from "antd";
import ScheduleUI from "./drawerContent";

// icons
import {
  ClockCircleOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

// axios
import axiosInstance from "../../helpers/axios";

const { Title } = Typography;

const TimeScheduleCard = () => {
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo, form } = useSelector(
    ({ userLoginReducer, merchantsReducer }) => ({
      userInfo: userLoginReducer.userInfo,
      form: merchantsReducer.form,
    }),
    isEqual
  );
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleOpenDrawer = () => setDrawerVisible(true);
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
    fetchScheduleData();
  };

  const fetchScheduleData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance(userInfo.token).get(
        `api/merchant-schedule/${form.id}`
      );
      setSchedule(response.data.data);
    } catch (err) {
      console.error("error >>>>", err);
    } finally {
      setLoading(false);
    }
  }, [form.id, userInfo.token]);

  useEffect(() => {
    if (form.id) {
      fetchScheduleData();
    }
  }, [form.id]);

  const formatTime = useCallback((time) => {
    if (!time) return "";
    try {
      const [hours, minutes] = time.split(":");
      const date = new Date();
      date.setHours(parseInt(hours), parseInt(minutes));
      return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
    } catch (error) {
      return time;
    }
  }, []);

  const getDaySchedule = useCallback(
    (dayName) => {
      if (!schedule.length) return null;
      return schedule.find((day) => day[dayName])?.[dayName];
    },
    [schedule]
  );

  const renderDaySchedule = useCallback(
    (dayName) => {
      const daySchedule = getDaySchedule(dayName);

      if (!daySchedule) {
        return (
          <div className="flex items-center gap-2 text-gray-500">
            <ClockCircleOutlined />
            <span>Schedule not available</span>
          </div>
        );
      }

      if (daySchedule.isNotAvailable) {
        return (
          <div className="flex items-center gap-2 text-red-500  p-2">
            <CloseCircleOutlined />
            <span>Store is not available today</span>
          </div>
        );
      }

      if (daySchedule.is24HoursDaily) {
        return (
          <div className="flex items-center gap-2 text-gray-500 p-2">
            <ClockCircleOutlined className="text-blue-500 text-sm" />
            <span>Open for 24 hours</span>
          </div>
        );
      }

      return (
        <div className="flex gap-8  p-2">
          <div className="flex items-center gap-2">
            <ClockCircleOutlined className="text-blue-500" />
            <div>
              <span className="text-gray-500 block text-[10px]">
                Time Open:
              </span>
              <span className="font-medium text-xs">
                {formatTime(daySchedule.start_time)}
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <ClockCircleOutlined className="text-blue-500" />
            <div>
              <span className="text-gray-500 block text-[10px]">
                Time Close:
              </span>
              <span className="font-medium text-xs">
                {formatTime(daySchedule.end_time)}
              </span>
            </div>
          </div>
        </div>
      );
    },
    [getDaySchedule, formatTime]
  );

  const renderScheduleInfo = () => {
    if (loading) {
      return (
        <div className="flex justify-center w-full py-4">
          <Spin size="default" />
        </div>
      );
    }

    return (
      <Descriptions
        bordered
        column={1}
        size="small"
        labelStyle={{
          width: "150px",
          fontWeight: 500,
          backgroundColor: "#fafafa",
        }}
      >
        <Descriptions.Item label="Monday">
          {renderDaySchedule("monday")}
        </Descriptions.Item>
        <Descriptions.Item label="Tuesday">
          {renderDaySchedule("tuesday")}
        </Descriptions.Item>
        <Descriptions.Item label="Wednesday">
          {renderDaySchedule("wednesday")}
        </Descriptions.Item>
        <Descriptions.Item label="Thursday">
          {renderDaySchedule("thursday")}
        </Descriptions.Item>
        <Descriptions.Item label="Friday">
          {renderDaySchedule("friday")}
        </Descriptions.Item>
        <Descriptions.Item label="Saturday">
          {renderDaySchedule("saturday")}
        </Descriptions.Item>
        <Descriptions.Item label="Sunday">
          {renderDaySchedule("sunday")}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <Card className="w-full max-w-2xl mx-auto shadow-sm mb-8">
      <div className="flex justify-between items-center mb-6">
        <Title level={4} className="!mb-0">
          Time Schedule
        </Title>
        <Button
          type="primary"
          onClick={handleOpenDrawer}
          className="flex items-center"
          size="middle"
          disabled={loading}
        >
          <EditOutlined />
          Edit
        </Button>
      </div>

      {renderScheduleInfo()}

      <Drawer
        placement="right"
        onClose={handleCloseDrawer}
        open={drawerVisible}
        width={580}
      >
        <ScheduleUI />
      </Drawer>
    </Card>
  );
};

export default TimeScheduleCard;
