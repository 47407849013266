import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import {
  Layout,
  Typography,
  Radio,
  List,
  Row,
  Col,
  Button,
  Form,
  Card,
  Modal,
  notification,
  Descriptions,
  Alert,
} from "antd";
import {
  LeftOutlined,
  BankOutlined,
  WalletOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";

// Components
import Wrapper from "../components/wrapper";

// Utilities
import renderInput from "../../src/components/utilities/inputForms";
import axiosInstance from "../helpers/axios";
import formatCurrency from "../helpers/formatCurrency";

//Actions
import { fetchTotalBalance } from "../redux/actions/partnerFinance";

const { Content: LayoutContent } = Layout;
const { Title, Text } = Typography;

const openNotification = (title, message) => {
  notification[title.toLowerCase()]({
    message: title,
    description: message,
    duration: 7,
  });
};

const TransferFund = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userInfo, totalBalance } = useSelector(
    ({ userLoginReducer, partnerFinanceReducer }) => ({
      userInfo: userLoginReducer.userInfo,
      totalBalance: partnerFinanceReducer.totalBalance,
    }),
    isEqual
  );

  const [payoutChannelType, setPayoutChannelType] = useState("EWALLET");
  const [payoutChannels, setPayoutChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputData, setInputData] = useState(null);
  const [isFirstAttempt, setIsFirstAttempt] = useState(true);
  const [inputOtp, setInputOtp] = useState(null);
  const [otpToken, setOtpToken] = useState(null);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [withdrawFee, setWithdrawFee] = useState(11.2);
  const [OTPError, setOTPError] = useState("");

  const [confirmDetailsModal, setConfirmDetailsModal] = useState(false);
  const [timer, setTimer] = useState(60); // State for countdown
  const [isCounting, setIsCounting] = useState(false);
  const availableBalance = totalBalance;
  const xendItFee = 11.2; // Fixed Xendit fee
  const transactionFeePercentage = 1 / 100; // 0.5% transaction fee

  const maxWithdrawableAmount =
    (availableBalance - xendItFee) / (1 + transactionFeePercentage);

  // get the available balance
  // get thre 0.5% of available balance
  // add the pe

  //get maximum fee to get the withdrawable balance

  const schema = yup.object().shape({
    accountName: yup.string().required(),
    accountNumber: yup.number().required(),
    amount: yup
      .number()
      .required("Amount is required")
      .typeError(
        "Amount must be a number without commas or special characters."
      )
      .positive("Amount must be greater than zero")
      .test("is-not-greater-than-balance", ``, function (value) {
        return value <= maxWithdrawableAmount;
      }),
    email: yup
      .string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),
  });

  let defaultValues = {
    accountName: "",
    accountNumber: "",
    amount: null,
    email: "",
  };

  const { control, handleSubmit, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
  });

  const amountToWithdraw = watch("amount");

  useEffect(() => {
    const transactionFee = amountToWithdraw * transactionFeePercentage;
    const totalFee = transactionFee + xendItFee;

    setWithdrawFee(totalFee.toFixed(2));
  }, [amountToWithdraw]);

  useEffect(() => {
    let interval = null;

    if (isCounting && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsCounting(false);
    }

    return () => clearInterval(interval);
  }, [isCounting, timer]);

  useEffect(() => {
    dispatch(fetchTotalBalance());
  }, [dispatch]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleSubmitOtp = async () => {
    await verifyOtpAndInitiatePayout();
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const resendOTP = async (contactNumber) => {
    try {
      const response = await axiosInstance(otpToken).post(
        `https://dory-service.dorydelivery.com/api/otp/send/${contactNumber}/dory-platform?isInitial=${isFirstAttempt}`,
        {
          headers: {
            Authorization: `Bearer ${otpToken}`,
          },
        }
      );

      if (response.data) {
        setOtpToken(response.data.token);
        setIsFirstAttempt(false);
        setSendOtpLoading(false);

        setTimer(60);
        setIsCounting(true);
        return true;
      } else {
        setSendOtpLoading(false);
        return false;
      }
    } catch (error) {
      setSendOtpLoading(false);
      console.log(">>> something went wrong resending OTP");
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const onRadioChange = (value) => {
    setPayoutChannelType(value);
    setSelectedChannel(null);
  };

  const sendOtp = async (contactNumber) => {
    try {
      setSendOtpLoading(true);

      const response = await axiosInstance().post(
        `https://dory-service.dorydelivery.com/api/otp/send/${contactNumber}/dory-platform?isInitial=${isFirstAttempt}`
      );

      if (response.data) {
        setOtpToken(response.data.token);
        setIsFirstAttempt(false);
        setSendOtpLoading(false);

        setTimer(60);
        setIsCounting(true);

        return true;
      } else {
        setSendOtpLoading(false);
        return false;
      }
    } catch (error) {
      setSendOtpLoading(false);
      console.log(">>> error sending OTP:", error);
    }
  };

  const onConfirm = async () => {
    try {
      const otpSent = await sendOtp(userInfo.contact_number);
      if (otpSent) {
        setConfirmDetailsModal(false);
        showModal();
      }
    } catch (error) {
      console.log(">>> error submitting form:", error);
    }
  };

  const onSubmit = async (values) => {
    try {
      setConfirmDetailsModal(true);

      // if (1000 < values.amount) {
      //   return openNotification(
      //     "WARNING",
      //     "The minimum withdrawal limit is 1,000"
      //   );
      // }

      setInputData(values);

      // const otpSent = await sendOtp(userInfo.contact_number);
      // if (otpSent) {
      //   showModal();
      // }
    } catch (error) {
      // console.log(">>> error submitting form:", error);
    }
  };

  const verifyOtpAndInitiatePayout = async () => {
    try {
      setVerifyOtpLoading(true);
      setOTPError("");
      const response = await axiosInstance().get(
        `https://dory-service.dorydelivery.com/api/otp/verify/${userInfo.contact_number}/dory-platform/${inputOtp}`,
        {
          headers: {
            Authorization: `Bearer ${otpToken}`,
          },
        }
      );

      if (response.data) {
        if (response.data.is_verified) {
          setOTPError("");
          const withdrawResponse = await axiosInstance(userInfo.token).post(
            `/api/wallet/${userInfo.id}/withdraw/partner`,
            {
              channel_code: selectedChannel.channel_code,
              account_holder_name: inputData.accountName,
              account_number: inputData.accountNumber,
              amount: inputData.amount,
              email: inputData.email,
              fee: withdrawFee,
            }
          );

          if (withdrawResponse.data) {
            openNotification("SUCCESS", withdrawResponse.data.message);
            closeModal();
            goBack();
          }
          setVerifyOtpLoading(false);
        } else {
          console.log(">>> wrong otp please try again!");

          setOTPError("Invalid OTP Code Try Again.");

          setVerifyOtpLoading(false);
        }
      }
      console.log(
        ">>> verifyOtpAndInitiatePayout response.data:",
        response.data
      );
    } catch (error) {
      setVerifyOtpLoading(false);
      console.log(">>> error verifying OTP:", error);
    }
  };

  const fetchPayoutChannels = async (channel_category) => {
    try {
      const response = await axiosInstance(userInfo.token).get(
        `/api/wallet/channelcode?channel_category=${channel_category}`
      );

      // const response = await axios.get(
      //   `https://api.xendit.co/payouts_channels?currency=PHP&channel_category=${payoutChannelType}`,
      //   {
      //     auth: {
      //       username:
      //         "",
      //       password: "",
      //     },
      //   }
      // );

      // console.log(">>> response.data:", response.data);

      if (response.data) {
        setPayoutChannels(response.data.result);
      }
    } catch (error) {
      console.log(">>> error getting xendit payout channels:", error);
    }
  };

  useEffect(() => {
    fetchPayoutChannels(payoutChannelType);
  }, [payoutChannelType]);

  const handleItemClick = (item) => {
    console.log("Clicked item:", item);
    setSelectedChannel(item);
  };

  const renderContent = () => {
    return (
      <LayoutContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "baseline",
            marginLeft: 10,
          }}
        >
          <LeftOutlined
            onClick={goBack}
            style={{
              fontSize: 20,
              color: "gray",
            }}
          />
          <h1 onClick={goBack} style={{ fontSize: 23, color: "gray" }}>
            Withdraw
          </h1>
        </div>

        <div className="mt-12 px-8">
          <Title level={2}>Transfer to</Title>
          <div>
            <Radio.Group
              defaultValue="EWALLET"
              size="large"
              buttonStyle="solid"
            >
              <Radio.Button
                value="EWALLET"
                style={{
                  padding: 12,
                  height: 62,
                  width: 140,
                  alignItems: "center",
                }}
                onChange={(e) => onRadioChange(e.target.value)}
              >
                <WalletOutlined className="mr-1" /> E-wallet
              </Radio.Button>
              <Radio.Button
                value="BANK"
                style={{
                  padding: 12,
                  height: 62,
                  width: 140,
                  alignItems: "center",
                }}
                onChange={(e) => onRadioChange(e.target.value)}
              >
                <BankOutlined className="mr-1" />
                Bank
              </Radio.Button>
            </Radio.Group>
          </div>

          {selectedChannel === null ? (
            <div
              className="mt-4"
              style={{
                maxHeight: "380px",
                overflowY: "auto",
                border: "solid 1px #d9d9d9",
                borderRadius: 12,
              }}
            >
              <List
                size="large"
                bordered
                dataSource={payoutChannels}
                renderItem={(item) => (
                  <List.Item
                    onClick={() => handleItemClick(item)}
                    style={{
                      cursor: "pointer",
                      padding: "15px",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#f0f8ff"; // Light blue when hovered
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "white"; // Reset background when not hovered
                    }}
                  >
                    {item.channel_name}
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <div className="mt-8">
              <Card style={{ width: "50%" }}>
                <div>
                  <Title level={4}>{selectedChannel.channel_name}</Title>
                </div>

                <div>
                  <div className="my-4">
                    <Alert
                      message={`  The maximum withdrawal amount is
                              ${formatCurrency(maxWithdrawableAmount)}, after
                              deducting transaction fees.`}
                      type="info"
                    />
                  </div>

                  <Form
                    layout="vertical"
                    initialValues={{
                      requiredMarkValue: true,
                    }}
                    requiredMark={true}
                    onFinish={handleSubmit(onSubmit)}
                  >
                    <>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          {renderInput(
                            {
                              label: "Amount",
                              name: "amount",
                              errors: errors,
                              required: "true",
                            },
                            control
                          )}
                          {renderInput(
                            {
                              label: "Account Name",
                              name: "accountName",
                              errors: errors,
                              required: "true",
                            },
                            control
                          )}
                          {renderInput(
                            {
                              label: "Account Number",
                              name: "accountNumber",
                              errors: errors,
                              type: "text",
                              required: "true",
                            },
                            control
                          )}
                          {renderInput(
                            {
                              label: "Email",
                              name: "email",
                              errors: errors,
                              required: "true",
                            },
                            control
                          )}

                          <div className="text-right mt-5">
                            <Button
                              key="back"
                              style={{ marginRight: "12px" }}
                              onClick={() => setSelectedChannel(null)}
                              disabled={sendOtpLoading}
                            >
                              Cancel
                            </Button>
                            <Button
                              htmlType="submit"
                              type="primary"
                              // disabled={sendOtpLoading}
                              // loading={sendOtpLoading}
                            >
                              Continue
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  </Form>
                </div>
              </Card>
            </div>
          )}
        </div>

        <Modal
          title="Enter OTP"
          open={isModalOpen}
          onCancel={closeModal}
          okText="Submit"
          maskClosable={false}
          confirmLoading={verifyOtpLoading}
          footer={null}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: 42,
            }}
          >
            <p style={{ fontSize: 12 }}>
              Enter the 6-digit OTP sent to your mobile number:{" "}
              {userInfo?.contact_number}.
            </p>
            <OtpInput
              value={inputOtp}
              onChange={setInputOtp}
              numInputs={6}
              renderSeparator={<span className="m-3"> - </span>}
              inputStyle={{
                width: 32,
                height: 42,
                border: "thin solid",
                borderRadius: 8,
              }}
              renderInput={(props) => <input size="large" {...props} />}
            />

            {OTPError && <Text type="danger">{OTPError}</Text>}

            <div
              className="mt-8"
              style={{
                width: "90%",
              }}
            >
              {verifyOtpLoading ? (
                <div>
                  <div className="flex items-center justify-center">
                    <LoadingOutlined style={{ fontSize: 28 }} />
                  </div>
                  <div className="flex items-center justify-center mt-2">
                    <p>Processing...</p>
                  </div>
                </div>
              ) : (
                <Button type="primary" block onClick={handleSubmitOtp}>
                  Submit
                </Button>
              )}

              {/* test */}
              <Button
                type="text"
                block
                onClick={() => {
                  resendOTP(userInfo.contact_number);
                }}
                disabled={isCounting} // Disable the button while the countdown is active
              >
                {isCounting ? `Resend OTP in ${timer}s` : "Resend OTP"}
              </Button>
              {/* test */}
            </div>
          </div>
        </Modal>
        {/*  confirmation of details */}
        <Modal
          title="Confirm Details"
          open={confirmDetailsModal}
          okText={"Confirm"}
          onOk={() => {
            onConfirm();
          }}
          confirmLoading={sendOtpLoading}
          onCancel={() => {
            setConfirmDetailsModal(false);
          }}
          width={600}
        >
          {payoutChannelType === "BANK" && (
            <Alert
              message=""
              description={
                <p style={{ fontSize: 12 }}>
                  If your withdrawal has not appeared in bank account, please
                  allow 3 to 5 business days. If it still hasn’t arrived, kindly
                  create a ticket in the Dory Partners Discord.
                </p>
              }
              type="info"
              showIcon
              className="mt-4"
            />
          )}
          <div className="mt-6">
            <Descriptions
              title=""
              bordered
              column={1}
              items={[
                {
                  key: "1",
                  label: "Amount",
                  children: `${parseFloat(inputData?.amount).toFixed(2)}`,
                },
                {
                  key: "2",
                  label: "Account Name",
                  children: `${inputData?.accountName}`,
                },
                {
                  key: "3",
                  label: "Account Number",
                  children: `${inputData?.accountNumber}`,
                },
                {
                  key: "4",
                  label: "Email",
                  children: `${inputData?.email}`,
                },
                {
                  key: "5",
                  label: (
                    <>
                      <p>Payment Service Charge</p>
                      <p style={{ fontSize: 10, fontStyle: "italic" }}>
                        Please note that this amount goes to our third-party
                        payment provider.
                      </p>
                    </>
                  ),
                  children: `${(
                    transactionFeePercentage * parseFloat(inputData?.amount) +
                    parseFloat(xendItFee)
                  ).toFixed(2)}`,
                },
              ]}
            />
          </div>
          <div className="m-2 text-center pt-4 mb-8">
            <h5 style={{ color: "#616161", fontSize: 12 }}>
              Total Deduction from eWallet
            </h5>
            <h2 style={{ fontSize: 22 }}>
              -{" "}
              {formatCurrency(
                (
                  parseFloat(inputData?.amount) +
                  parseFloat(xendItFee) +
                  transactionFeePercentage * parseFloat(inputData?.amount)
                ).toFixed(2)
              )}
            </h2>
          </div>
        </Modal>
        {/*  */}
      </LayoutContent>
    );
  };

  return <Wrapper content={renderContent()} />;
};

export default TransferFund;
