import actions from "../actionTypes";
const { CREATE_EMAIL } = actions;

const userInitialState = {
  data: [],
  loading: false,
  error: null,
};

export const emailReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_EMAIL.REQUEST:
      return { ...state, loading: true };
    case CREATE_EMAIL.SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    case CREATE_EMAIL.FAILURE:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};


