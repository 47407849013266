import React from 'react';
import { useHistory } from 'react-router-dom';

const EmailCheckPage = () => {
  const history = useHistory();

  const navigateToLogin = () => {
    history.push('/partner-signin');
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f1f8f4', 
      padding: '20px',
    },
    icon: {
      width: '100px',
      height: '100px',
      marginBottom: '20px',
    },
    message: {
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#388e3c', 
      marginBottom: '20px',
      maxWidth: '80%',
    },
    additionalText: {
      fontSize: '16px',
      color: '#2c6c40', 
      textAlign: 'center',
      marginBottom: '30px',
      maxWidth: '80%',
    },
    button: {
      backgroundColor: '#4caf50', 
      color: '#fff',
      border: 'none',
      padding: '15px 30px',
      borderRadius: '8px',
      fontSize: '18px',
      cursor: 'pointer',
      transition: 'background-color 0.3s, transform 0.3s',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    buttonHover: {
      backgroundColor: '#388e3c', 
      transform: 'scale(1.05)',
    },
  };

  return (
    <div style={styles.container}>
      <img
        src="https://img.icons8.com/ios/100/000000/key.png" 
        alt="Key Icon"
        style={styles.icon}
      />
      <p style={styles.message}>
        We have sent a password reset link to your email address. Please check your email and follow the instructions to reset your password.
      </p>
      <p style={styles.additionalText}>
        If you don’t see the email, please check your spam or junk folder. If you still don’t find it, try requesting a new password reset link.
      </p>
      <button
        style={styles.button}
        onClick={navigateToLogin}
        onMouseOver={(e) => e.target.style.backgroundColor = styles.buttonHover.backgroundColor}
        onMouseOut={(e) => e.target.style.backgroundColor = styles.button.backgroundColor}
        onMouseDown={(e) => e.target.style.transform = styles.buttonHover.transform}
        onMouseUp={(e) => e.target.style.transform = 'scale(1)'}
      >
        Login Screen
      </button>
    </div>
  );
};

export default EmailCheckPage;
