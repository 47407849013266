import React, { useState } from 'react';
import { Button, Input, Space, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import axiosInstance from '../helpers/axios'; 
// import { useSelector } from "react-redux";

const { Text } = Typography;

const SendOTPscreen = () => {

  const history = useHistory();
  const location = useLocation();
  const [otpCode, setOtpCode] = useState('');
  const [contact] = useState(location.state?.contact || '');
  const [tempToken] = useState(location.state?.token || ''); 
  const [userId] = useState(location.state?.userId || ''); 
  const [errorMessage, setErrorMessage] = useState(''); 
  const [loading, setLoading] = useState(false); 

  const handleVerify = async () => {
  setLoading(true); 
  try {
    const response = await axiosInstance().post(`/api/admin-account/reset-password/${contact}/${otpCode}?otpToken=${tempToken}`);

    if (response.data.success) {
      
      if (response.data.hasEmail) {
        history.push('/email-sent');
      } 
    } else {
     
      if (response.data.message === "Invalid OTP code") {
        setErrorMessage('Invalid OTP. Please try again.');
      } else {
        history.push({
          pathname: '/create-email',
          state: { userId, otpCode }
        });
      }
    }
  } catch (error) {
    console.error('An error occurred while verifying the OTP:', error);
    setErrorMessage('Invalid OTP. Please try again.');
  } finally {
    setLoading(false); 
  }
};

  
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              OTP Verification
            </h1>
            <Space size="large" />
            <Text className="text-lg text-gray-700 dark:text-gray-300">
              Please enter the one-time PIN (OTP) that we sent to your mobile number.
            </Text>

            <Space size="large" />
            <Space size="large" />

            <Input
              type="text"
              placeholder="Enter OTP"
              maxLength={6}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={otpCode}
              onChange={(e) => setOtpCode(e.target.value)}
            />

            <Space size="large" />
            <Space size="large" />

            <div className="flex items-center justify-between">
              <Button
                type="primary"
                block
                size="large"
                style={{ backgroundColor: '#00BF63', borderColor: '#00BF63' }}
                onClick={handleVerify}
                loading={loading} 
              >
                Verify
              </Button>
            </div>

            {errorMessage && (
              <Space size="large">
                <Text
                  className="text-red-500 text-center"
                  style={{ display: 'block' }}
                >
                  {errorMessage}
                </Text>
              </Space>
            )}

            <Space size="medium" />

            {/* <div>
              <Button
                type="link"
                onClick={() => {
                  // Handle resend OTP
                }}
                disabled={false}
              >
                Resend <Text style={{ color: 'gray' }}>(60 secs)</Text>
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendOTPscreen;
