import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Form, Input, Badge, Button, Tooltip } from "antd";
import ServerSideTable from "../table/serverSideTable";
import { fetchSubOpsReferredRider } from "../../redux/actions/subOperatorReferralRider";
import Icon from "@mdi/react";
import { PlusOutlined } from "@ant-design/icons";
import { toggleModalCreateRider } from "../../redux/actions/riderInMyArea";
import CreateRiderModal from "../riderInMyArea/modal";
import { mdiHandCoinOutline } from "@mdi/js";
const { Content: LayoutContent } = Layout;
const { Search } = Input;

const SubOperatorRiderTable = ({ merchantId }) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedRole, setSelectedRole] = useState("rider");
  const [changeCommission, setChangeCommission] = useState(false);
  const [riderDetails, setRiderDetails] = useState({});
  const {
    loading,
    subOpsreferredRiderData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(({ subOpsReferredRiderReducer }) => ({
    subOpsreferredRiderData: subOpsReferredRiderReducer.subOpsreferredRiderData,
    totalRecord: subOpsReferredRiderReducer.subOpsreferredRiderTotalRecord,
    nextPage: subOpsReferredRiderReducer.subOpsreferredRiderNextPage,
    previousPage: subOpsReferredRiderReducer.subOpsreferredRiderPreviousPage,
    currentPage: subOpsReferredRiderReducer.subOpsreferredRiderCurrentPage,
    loading: subOpsReferredRiderReducer.loading,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      userId: merchantId,
      role: selectedRole,
    };
    dispatch(fetchSubOpsReferredRider(payload));
  }, [delaySearchText, selectedRole, dispatch, merchantId]);

  useEffect(() => {
    const initialPayload = {
      page: 1,
      limit: 15,
      userId: merchantId,
      role: selectedRole,
    };
    dispatch(fetchSubOpsReferredRider(initialPayload));
  }, [dispatch, merchantId, selectedRole]);

  const onAdd = () => {
    dispatch(toggleModalCreateRider(true));
  };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      userId: merchantId,
      role: selectedRole,
    };
    dispatch(fetchSubOpsReferredRider(payload));
  };

  const onEdit = ({ data }) => {
    setChangeCommission(true);
    setRiderDetails(data);
    dispatch(toggleModalCreateRider(true));
  };

  const customFilters = () => (
    <div className="mb-2">
      <div
        className="p-2 flex justify-between"
        style={{ backgroundColor: "#fafafa" }}
      >
        <Form layout="inline">
          <Form.Item label="Search">
            <Search
              placeholder="input search text"
              allowClear
              style={{ width: "15VW" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={onSearch}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              gap: 5,
            }}
          >
            <Button
              size="small"
              type="text"
              onClick={() => onEdit({ data: original })}
            >
              <Tooltip title="Rider Commission">
                <span className="anticon anticon-check-square">
                  <Icon path={mdiHandCoinOutline} size={0.7} />
                </span>
              </Tooltip>
            </Button>
          </div>
        ),
      },

      {
        Header: "Duty Status",
        accessor: (row) =>
          row.active_status === "offline" || row.active_status === null ? (
            <Badge status="error" text="Off Duty" />
          ) : (
            <Badge status="success" text="On Duty" />
          ),
      },
      {
        Header: "Name",
        accessor: "fullName",
      },
      {
        Header: "Contact number",
        accessor: "contact_number",
      },
      {
        Header: "Preferred Delivery City",
        accessor: (row) =>
          row.pref_delivery_city === null ? (
            <p style={{ color: "black" }}>Group Area</p>
          ) : (
            <p style={{ color: "black" }}>{row.pref_delivery_city}</p>
          ),
      },
      {
        Header: "Preferred Delivery Province",
        accessor: (row) =>
          row.pref_delivery_province === null ? (
            <p style={{ color: "black" }}>Group Area</p>
          ) : (
            <p style={{ color: "black" }}>{row.pref_delivery_province}</p>
          ),
      },
      {
        Header: "Delivered Count",
        accessor: "delivered_count",
      },
    ],
    []
  );

  const renderContent = () => (
    <LayoutContent>
      <ServerSideTable
        columns={columns}
        data={subOpsreferredRiderData}
        loading={loading}
        serverSidePagination={{
          totalRecord,
          onClickNextPage: () => {
            dispatch(
              fetchSubOpsReferredRider({
                ...nextPage,
                userId: merchantId,
                role: selectedRole,
              })
            );
          },
          onClickPreviousPage: () => {
            dispatch(
              fetchSubOpsReferredRider({
                ...previousPage,
                userId: merchantId,
                role: selectedRole,
              })
            );
          },
          currentPage,
          nextPage,
          previousPage,
        }}
        customFilters={customFilters}
        extra={[
          <Button
            key="1"
            onClick={onAdd}
            className=""
            icon={<PlusOutlined />}
            type="primary"
          >
            Create Rider
          </Button>,
        ]}
      />
    </LayoutContent>
  );

  return (
    <>
      {renderContent()}
      <CreateRiderModal
        changeCommission={changeCommission}
        setChangeCommission={setChangeCommission}
        riderDetails={riderDetails}
        type="sub_operator"
      />
    </>
  );
};

export default SubOperatorRiderTable;
