// hooks
import { useEffect, useState, useCallback, useMemo } from "react";
// components
import { Card, TimePicker, Switch, Typography, Popconfirm, Spin } from "antd";

// helpers
import dayjs from "dayjs";
import axiosInstance from "../../helpers/axios";

// redux
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";

// icons
import { ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const LoadingOverlay = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg flex items-center gap-3">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      <span className="text-gray-700">Loading...</span>
    </div>
  </div>
);

const ScheduleUI = () => {
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userInfo, form } = useSelector(
    ({ userLoginReducer, merchantsReducer }) => ({
      userInfo: userLoginReducer.userInfo,
      form: merchantsReducer.form,
    }),
    isEqual
  );

  const daysOfWeek = useMemo(
    () => [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    []
  );

  const fetchScheduleData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance(userInfo.token).get(
        `api/merchant-schedule/${form.id}`
      );
      setSchedule(response.data.data);
    } catch (err) {
      console.error("error >>>>", err);
    } finally {
      setLoading(false);
    }
  }, [form.id, userInfo]);

  useEffect(() => {
    if (form.id) {
      fetchScheduleData();
    }
  }, [form.id]);

  const handleTimeChange = useCallback((day, type, time) => {
    setSchedule((prev) =>
      prev.map((item) => ({
        ...item,
        [day]: {
          ...item[day],
          [type]: time?.format("HH:mm:ss"),
        },
      }))
    );
  }, []);

  const toggleAvailability = async (day) => {
    const dayKey = day.toLowerCase();

    const scheduleEntry = schedule.find((item) => item.id);

    if (!scheduleEntry) {
      console.error("Schedule entry not found for", day);
      return;
    }

    const scheduleId = scheduleEntry.id;

    const dayData = scheduleEntry[dayKey];

    const updatedSchedule = {
      day: dayKey,
      start_time: dayData.isNotAvailable ? "08:00:00" : "00:00:00",
      end_time: dayData.isNotAvailable ? "12:00:00" : "00:00:00",
      is24HoursDaily: false,
      isNotAvailable: !dayData.isNotAvailable,
    };

    setLoading(true);
    try {
      await axiosInstance(userInfo.token).put(
        `api/merchant-schedule/${scheduleId}`,
        updatedSchedule
      );

      setSchedule((prev) =>
        prev.map((item) =>
          item.id === scheduleId
            ? { ...item, [dayKey]: { ...dayData, ...updatedSchedule } }
            : item
        )
      );
    } catch (error) {
      console.error("Failed to update schedule:", error);
    } finally {
      setLoading(false);
    }
  };

  const setAllTo24Hours = async (value) => {
    setLoading(true);

    try {
      const updatePromises = schedule.map(async (item) => {
        const updatedItem = { ...item };

        for (const day of daysOfWeek) {
          const lowerDay = day.toLowerCase();
          if (updatedItem[lowerDay]) {
            const updatedSchedule = {
              is24HoursDaily: value,
              isNotAvailable: false,
              start_time: value ? "00:00:00" : updatedItem[lowerDay].start_time,
              end_time: value ? "24:00:00" : updatedItem[lowerDay].end_time,
              day: lowerDay,
            };

            await axiosInstance(userInfo.token).put(
              `api/merchant-schedule/${updatedItem.id}`,
              updatedSchedule
            );

            updatedItem[lowerDay] = {
              ...updatedItem[lowerDay],
              ...updatedSchedule,
            };
          }
        }

        return updatedItem;
      });

      const updatedSchedules = await Promise.all(updatePromises);
      setSchedule(updatedSchedules);
    } catch (error) {
      console.error("Failed to update all schedules to 24 hours:", error);
    } finally {
      setLoading(false);
    }
  };

  const is24HoursEnabled =
    schedule[0] &&
    daysOfWeek.every((day) => schedule[0][day.toLowerCase()]?.is24HoursDaily);

  const getConfirmMessage = (currentStatus) => {
    return currentStatus
      ? "Are you sure you want to disable the 24hrs daily schedule of this merchant?"
      : "Are you sure you want to set the merchant's schedule to operate 24/7?";
  };

  const getConfirmButton = (currentStatus) => {
    return currentStatus ? "Disable 24hrs Daily" : "Set";
  };

  const setDayTo24Hours = async (day, value) => {
    setLoading(true);
    const dayKey = day.toLowerCase();

    const scheduleEntry = schedule.find((item) => item.id);
    if (!scheduleEntry) {
      console.error("Schedule entry not found for", day);
      setLoading(false);
      return;
    }

    const scheduleId = scheduleEntry.id;
    const dayData = scheduleEntry[dayKey];

    const updatedSchedule = {
      day: dayKey,
      is24HoursDaily: value,
      isNotAvailable: false,
      start_time: value ? "00:00:00" : dayData.start_time,
      end_time: value ? "24:00:00" : dayData.end_time,
    };

    try {
      await axiosInstance(userInfo.token).put(
        `api/merchant-schedule/${scheduleId}`,
        updatedSchedule
      );

      setSchedule((prev) =>
        prev.map((item) =>
          item.id === scheduleId
            ? {
                ...item,
                [dayKey]: {
                  ...dayData,
                  ...updatedSchedule,
                },
              }
            : item
        )
      );
    } catch (error) {
      console.error("Failed to update schedule for", day, error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (day) => {
    const dayKey = day.toLowerCase();

    const scheduleEntry = schedule.find((item) => item.id);
    if (!scheduleEntry) {
      console.error("Schedule entry not found for", day);
      return;
    }

    const scheduleId = scheduleEntry.id;
    const dayData = scheduleEntry[dayKey];

    if (!dayData || !dayData.start_time || !dayData.end_time) {
      console.error("Invalid time data for", day);
      return;
    }

    const updatedSchedule = {
      day: dayKey,
      start_time: dayData.start_time,
      end_time: dayData.end_time,
      is24HoursDaily: false,
      isNotAvailable: dayData.isNotAvailable,
    };

    setLoading(dayKey);
    try {
      await axiosInstance(userInfo.token).put(
        `api/merchant-schedule/${scheduleId}`,
        updatedSchedule
      );

      setSchedule((prev) =>
        prev.map((item) =>
          item.id === scheduleId
            ? {
                ...item,
                [dayKey]: {
                  ...dayData,
                  ...updatedSchedule,
                },
              }
            : item
        )
      );
    } catch (error) {
      console.error("Failed to update schedule for", day, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 relative ">
      {loading && <LoadingOverlay />}
      <div className={loading ? "pointer-events-none" : ""}>
        <h2 className="text-2xl font-semibold mb-6">Weekly Schedule</h2>
        <div className="flex gap-2 mb-6">
          <Text className="text-gray-500 text-sm">Set 24hrs Daily: </Text>
          <Popconfirm
            title={
              <div className="flex items-center gap-2">
                <span>{getConfirmMessage(is24HoursEnabled)}</span>
              </div>
            }
            onConfirm={() => setAllTo24Hours(!is24HoursEnabled)}
            okText={getConfirmButton(is24HoursEnabled)}
            cancelText="Cancel"
            placement="right"
          >
            <Switch
              checked={is24HoursEnabled}
              checkedChildren=""
              unCheckedChildren=""
            />
          </Popconfirm>
        </div>
        {schedule[0] &&
          daysOfWeek.map((day) => {
            const dayData = schedule[0][day.toLowerCase()];
            if (!dayData) return null;

            return (
              <Card key={day} title={day} className="mb-4 shadow-sm">
                <div className="flex flex-col space-y-4">
                  <div className="flex justify-end items-center gap-5">
                    <div className="flex flex-col items-center gap-2">
                      <Text
                        style={{ fontSize: "9px" }}
                        className="text-gray-500 font-bold"
                      >
                        Set Store Status
                      </Text>
                      <Switch
                        checked={!dayData.isNotAvailable}
                        onChange={() => toggleAvailability(day.toLowerCase())}
                        checkedChildren="Open"
                        unCheckedChildren="Closed"
                        className="w-16"
                      />
                    </div>
                    <div className="flex flex-col items-center gap-2">
                      <Text
                        style={{ fontSize: "9px" }}
                        className="text-gray-500 font-bold"
                      >
                        Set 24Hours
                      </Text>
                      <Switch
                        checked={dayData.is24HoursDaily}
                        onChange={(value) =>
                          setDayTo24Hours(day.toLowerCase(), value)
                        }
                        checkedChildren="24hrs"
                        unCheckedChildren=""
                        disabled={dayData.isNotAvailable}
                        className="w-16"
                      />
                    </div>
                  </div>
                  {!dayData.isNotAvailable && (
                    <div className="space-y-3">
                      {" "}
                      {/* // Added wrapper div with spacing */}
                      <div className="grid grid-cols-2 gap-3">
                        <div>
                          <Text className="text-xs text-gray-500 block mb-1">
                            Time Open
                          </Text>
                          <TimePicker
                            use12Hours
                            format="h:mm A"
                            value={
                              dayData.start_time
                                ? dayjs(dayData.start_time, "HH:mm:ss")
                                : null
                            }
                            onChange={(time) =>
                              handleTimeChange(
                                day.toLowerCase(),
                                "start_time",
                                time
                              )
                            }
                            disabled={
                              dayData.isNotAvailable || dayData.is24HoursDaily
                            }
                            className="w-full"
                            size="middle"
                          />
                        </div>
                        <div>
                          <Text className="text-xs text-gray-500 block mb-1">
                            Time Close
                          </Text>
                          <TimePicker
                            use12Hours
                            format="h:mm A"
                            value={
                              dayData.end_time
                                ? dayjs(dayData.end_time, "HH:mm:ss")
                                : null
                            }
                            onChange={(time) =>
                              handleTimeChange(
                                day.toLowerCase(),
                                "end_time",
                                time
                              )
                            }
                            disabled={
                              dayData.isNotAvailable || dayData.is24HoursDaily
                            }
                            className="w-full"
                            size="middle"
                          />
                        </div>
                      </div>
                      {/* Added save button for custom time */}
                      <button
                        onClick={() => handleSave(day.toLowerCase())}
                        className={`${
                          dayData.is24HoursDaily ? "bg-gray-300" : "bg-blue-500"
                        } w-full  text-white py-1 px-4 rounded text-sm disabled:opacity-50 disabled:cursor-not-allowed`}
                        disabled={
                          loading === day.toLowerCase() ||
                          dayData.isNotAvailable ||
                          dayData.is24HoursDaily
                        }
                      >
                        {loading === day.toLowerCase()
                          ? "Saving..."
                          : "Save Custom Time"}
                      </button>
                    </div>
                  )}
                  {dayData.isNotAvailable && (
                    <div className="bg-gray-100 p-3 rounded-md text-center">
                      <Text className="text-gray-500">
                        The store is closed on this day.
                      </Text>
                    </div>
                  )}
                </div>
              </Card>
            );
          })}
      </div>
    </div>
  );
};

export default ScheduleUI;
