import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { RIDER, RIDEROPERATORREFERRAL } = actions;

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: RIDER.TOGGLEVIEW, payload });
  };

export const toggleModalCreateRider =
  (payload = true) =>
  (dispatch) => {
    dispatch({ type: RIDER.TOGGLECREATERIDER, payload });
  };

export const setFormCreateRider = (payload) => (dispatch) => {
  dispatch({ type: RIDER.SETRIDERFORM, payload });
};

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: RIDER.TOGGLE, payload });
  };
export const setForm = (payload) => (dispatch) => {
  dispatch({ type: RIDER.SET, payload });
};

export const reportAccount = (payload) => async (dispatch, getState) => {
  dispatch({ type: RIDER.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      `/api/rider/report-rider`,
      payload
    );
    dispatch({ type: RIDER.CREATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: RIDER.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const fetchReportedRider = (payload) => async (dispatch, getState) => {
  dispatch({ type: RIDER.FETCHRIDERINMYAREA.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { id } = userInfo;

  try {
    let url = `/api/rider/fetch-area-rider/:partner_id`;

    if (payload !== undefined) {
      const { page, limit, searchText } = payload;
      url = `/api/rider/fetch-area-rider/${id}?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/rider/fetch-area-rider/${id}?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: RIDER.FETCHRIDERINMYAREA.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: RIDER.FETCHRIDERINMYAREA.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchRiderInMyArea = (payload) => async (dispatch, getState) => {
  dispatch({ type: RIDER.FETCHRIDERINMYAREA.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { id } = userInfo;

  try {
    let url = `/api/rider/fetch-area-rider/:partner_id`;

    if (payload !== undefined) {
      const { page, limit, searchText } = payload;
      url = `/api/rider/fetch-area-rider/${id}?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/rider/fetch-area-rider/${id}?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: RIDER.FETCHRIDERINMYAREA.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: RIDER.FETCHRIDERINMYAREA.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchReferredRider = (payload) => async (dispatch, getState) => {
  dispatch({ type: RIDER.FETCHREFERREDRIDER.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { id } = userInfo;

  try {
    let url = `/api/rider/fetch-referred-rider/:parter_id`;

    if (payload !== undefined) {
      const { page, limit, searchText } = payload;
      url = `/api/rider/fetch-referred-rider/${id}?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/rider/fetch-referred-rider/${id}?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: RIDER.FETCHREFERREDRIDER.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: RIDER.FETCHREFERREDRIDER.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const createRider = (payload) => async (dispatch, getState) => {
  dispatch({ type: RIDER.CREATERIDER.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).post(
      "/api/rider/manual-create",
      payload
    );

    if (userInfo.role === "sub_operator") {
      dispatch({ type: RIDEROPERATORREFERRAL.SET, payload: response });
    }

    if (userInfo.role === "operator_admin") {
      dispatch({ type: RIDER.CREATERIDER.SUCCESS, payload: response });
    }

    return response;
  } catch (error) {
    dispatch({
      type: RIDER.CREATERIDER.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const updateRiderCommission =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: RIDER.UPDATECOMMISSION.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();

    try {
      let token = "";
      if (userInfo !== null) token = userInfo.token;

      const response = await axiosInstance(token).patch(
        "/api/rider/commission",
        { ...payload, type: userInfo.role }
      );

      if (userInfo.role === "sub_operator") {
        dispatch({ type: RIDEROPERATORREFERRAL.UPDATE, payload: response });
      }

      if (userInfo.role === "operator_admin") {
        dispatch({ type: RIDER.UPDATECOMMISSION.SUCCESS, payload: response });
      }

      return response;
    } catch (error) {
      dispatch({
        type: RIDER.UPDATECOMMISSION.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
      return error;
    }
  };
